import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Approval, BlockOutlined, LibraryBooksOutlined, TaskAltOutlined } from '@material-ui/icons';
// material
import { useTheme } from '@material-ui/core/styles';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText,  } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import plusFill from '@iconify/icons-eva/plus-fill';
import downloadFill from '@iconify/icons-eva/download-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import { useSnackbar } from 'notistack';


// routes 

// ----------------------------------------------------------------------

UserMoreMenu.propTypes = {
  onDelete: PropTypes.func,
  userName: PropTypes.string
};

export default function UserMoreMenu({ text,onApprove, onDelete, onEdit, onDeactivate,onStatusChange,status,
  isOpportunity,oppName,oppId,fromCrmCalls,noEdit,addToLeadPhone,common,common2,linkImageWithTask,onDownload,onView, onExistingLead }) {
  
  const ref = useRef(null);
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const user = JSON.parse(localStorage.getItem('user'))
  const explodedPermissions =user?.exploded_permissions

  const handleNavigate = (id) => {
    navigate(`${PATH_DASHBOARD.client.addClient}/${'client'}`, { state: { crmName: oppName, fromCrm:true } });
  };
  
  const handleNavigateCrmCall = (fromCrmCalls,addToLeadPhone) => {
    setIsOpen(false)
    if(addToLeadPhone){
      console.log("add to lead",addToLeadPhone);
      navigate(PATH_DASHBOARD.crm.addlead, { state: { addToLeadPhone : addToLeadPhone } });
    }
    else{
      onExistingLead();
      console.log("add to existing lead");
    }
  };

  useEffect(() => {
    if(common && common.modalClose){
      setIsOpen(false)
    }
    if(common2 && common2.modalClose){
      setIsOpen(false)
    }
    if(linkImageWithTask && linkImageWithTask.modalClose){
      setIsOpen(false)
    }
  },[common?.modalClose,common2?.modalClose,linkImageWithTask?.modalClose])

  return (
    <>
      <IconButton sx={{color:theme.palette.grey.A100}} ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

        {[noEdit || 
        ((common && common.name) && <MenuItem onClick={() => common.function()}>
          {common.Iccon}
          <ListItemText sx={{marginLeft:2}} primary={common.name} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>),

        ((linkImageWithTask && linkImageWithTask.name) && <MenuItem onClick={() => linkImageWithTask.function()}>
          {linkImageWithTask.Iccon}
          <ListItemText sx={{marginLeft:2}} primary={linkImageWithTask.name} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>),

        ((common2  && common2.name) && <MenuItem onClick={() => common2.function()} >
        {common2.Iccon}
        <ListItemText sx={{marginLeft:2}} primary={common2.name} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>),

        (onView && <MenuItem onClick={() => { onView(); setIsOpen(false) }} >
        <ListItemIcon>
          <Icon icon={eyeFill} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>),

        (onDownload && <MenuItem onClick={() => { onDownload(); setIsOpen(false) }} >
        <ListItemIcon>
          <Icon icon={downloadFill} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Download" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>),

        (onEdit && <MenuItem onClick={() => { onEdit(); setIsOpen(false) }} >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
         
        </MenuItem>
      ),
    (onApprove && <MenuItem onClick={() => { onApprove(); setIsOpen(false) }} >
    <ListItemIcon>
      <Icon icon={eyeFill} width={24} height={24} />
    </ListItemIcon>
    <ListItemText primary="Approve" primaryTypographyProps={{ variant: 'body2' }} />

    </MenuItem>
    ),
      isOpportunity && (<MenuItem 
      onClick={() => {
        if(explodedPermissions?.some(permission => ['Inviting clients'].includes(permission))){
          handleNavigate(oppId)
        }else{
          enqueueSnackbar("Access Denied: You do not have sufficient permissions to add client", { variant: 'error' })
        }
      }} >
          <ListItemIcon>
            <LibraryBooksOutlined sx={{width:25,height:25}}/>
          </ListItemIcon>
          <ListItemText primary="Convert to Client" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>),
        fromCrmCalls &&
        (<>
          <MenuItem onClick={() => handleNavigateCrmCall(fromCrmCalls,addToLeadPhone)} >
            <ListItemIcon>
            <Icon icon={plusFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Add to Lead" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          {/* <MenuItem onClick={() => handleNavigateCrmCall(fromCrmCalls)} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
            <Icon icon={plusFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Add to existing Lead" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem> */}
        </>),
        status !== undefined && (
          <MenuItem onClick={() => { onStatusChange(); setIsOpen(false); }} >
            <ListItemIcon>
            {(status === 1 ? <BlockOutlined sx={{ width: 25, height: 25 }} /> : <TaskAltOutlined sx={{ width: 25, height: 25 }} /> )}
            </ListItemIcon>
            <ListItemText
              primary={text ? text : (status === 1 ? "Deactivate" : "Activate")}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        ),
        onDeactivate && (<MenuItem
          onClick={() => {
            onDeactivate()
            setIsOpen(false)
          }}
        >
          <ListItemIcon>
          <BlockOutlined sx={{width:25,height:25}}/>
          </ListItemIcon>
          <ListItemText primary={text ? text : "Deactivate"} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>),

        onDelete && (<MenuItem onClick={() => {
            onDelete()
            setIsOpen(false)
          }} >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>)
        ]}
      </Menu>
    </>
  );
}
