import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// material
import { Avatar, Box, Divider, Typography } from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import {
  addRecipients,
  onSendMessage,
  getConversation,
  getParticipants,
  markConversationAsRead,
  resetActiveConversation
} from '../../../redux/slices/chat';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
//
import ChatRoom from './ChatRoom';
import ChatMessageList from './ChatMessageList';
import ChatHeaderDetail from './ChatHeaderDetail';
import ChatMessageInput from './ChatMessageInput';
import ChatHeaderCompose from './ChatHeaderCompose';
import Messages from '../user/profile/Messages';
import axios from 'axios';
import { REST_API } from 'src/constants/Defaultvalues';


// ----------------------------------------------------------------------

const conversationSelector = (state) => {
  const { conversations, activeConversationId } = state.chat;
  const conversation = conversations.byId[activeConversationId];
  if (conversation) {
    return conversation;
  }
  return {
    id: null,
    messages: [],
    participants: [],
    unreadMessages: 0
  };
};

export default function ChatWindow({ products, allUser, requestDetails }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [conversatingUser, setConversatinguser] = useState(null)
  const { pathname } = useLocation();
  const { conversationKey } = useParams();
  const [conversationMessage, setconversationMesage] = useState([])
  const [clientSideMessage, setClientSideMessage] = useState([])
  const { contacts, recipients, participants, activeConversationId } = useSelector((state) => state.chat);
  const conversation = useSelector((state) => conversationSelector(state));
  const mode = conversationKey ? 'DETAIL' : 'COMPOSE';

  const displayParticipants = participants.filter((item) => item.id !== '8864c717-587d-472a-929a-8e5f298024da-0');

  let user = JSON.parse(localStorage.getItem('user'))
  const requestId = localStorage.getItem('requestId')
  const userType = user.user_type
  const userId = user.user_id
  const explodedPermissions = user?.exploded_permissions
  useEffect(() => {
    const getDetails = async () => {
      dispatch(getParticipants(conversationKey));
      try {
        dispatch(getConversation(conversationKey));
      } catch (error) {
        console.error(error);
        navigate(PATH_DASHBOARD.chat.new);
      }
    };
    if (conversationKey) {
      getDetails();
    } else if (activeConversationId) {
      dispatch(resetActiveConversation());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationKey]);

  useEffect(() => {
    if (activeConversationId) {
      dispatch(markConversationAsRead(activeConversationId));
    }
  }, [dispatch, activeConversationId]);

  const handleAddRecipient = (recipient) => {

    setConversatinguser(recipient)
    viewMessage(recipient)
    // dispatch(addRecipients(recipient));
  };
 
  const viewMessage = async (value) => {
    try {
  
      const data = {
        partnerId: value && value?.sender_id ? value.sender_id : (value ? value.id : conversatingUser.id), userId: userId, requestId: requestId,
        userType: userType
      }
  
      const res = await axios.post(`${REST_API}webservice/request/view-public-message`, data)
      if (res.data) {
          
          if(userType===1){
            setconversationMesage(res.data.message)
          }else{
            setClientSideMessage(res.data.message)
          }
      }
    } catch (error) {
      console.log(error, 'error in view message in admin side')
    }

  }
  
  const handleSendMessage = async (value) => {
    try {
      console.log(value,'----------------value when type -1')
      const res = await axios.post(`${REST_API}webservice/request/sent-public-message`, value)

      if (res.data.status == 1) {
        viewMessage(null)

      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSendAdminMsg = async (value) => {
   console.log(value, '$$$$$$$$$$$$$$$$')
    try {
      const res = await axios.post(`${REST_API}webservice/request/sent-public-message`, value)

      if (res.data.status == 1) {
        viewMessageFromClient()

        console.log(res.data)
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    viewMessageFromClient()
  }, [])
  const viewMessageFromClient = async () => {
    try {
      const data = { partnerId: requestDetails?.added_by, userId: userId, requestId: requestId, userType }
      const res = await axios.post(`${REST_API}webservice/request/view-public-message`, data)
      if (res.data) {
        setClientSideMessage(res.data.message)
        console.log(res.data, '%%%%%%%%%%%%%%%%')
      }
    } catch (error) {
      console.log(error, 'error in view message in client side')
    }
  }

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      {/* {mode === 'DETAIL' ? (
        <ChatHeaderDetail participants={displayParticipants} />
      ) : (
        <ChatHeaderCompose
          contactss={allUser}
          recipients={recipients}
          contacts={Object.values(contacts.byId)}
          onAddRecipient={handleAddRecipient}
        />
      )}

      <Divider /> */}
      {userType == 1 || (explodedPermissions?.some(permission => ['Create Request','Edit Request','Delete request','Add vendor to request','Add client to request'].includes(permission)))? (
        <>
          <ChatHeaderCompose
            contactss={allUser}
            recipients={recipients}
            contacts={Object.values(contacts.byId)}
            onAddRecipient={handleAddRecipient}
          />
          <Divider />
        </>
      ) : (
        <>
       
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 3, marginY: 2 }}>
            <Box sx={{ position: 'relative' }}>
              <Avatar src={requestDetails.pic} alt={requestDetails.send_to} />
            </Box>
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2">{requestDetails.send_to}</Typography>
            </Box>
          </Box>
          <Divider />
        </>
      )}

      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <ChatMessageList
            conversationMessage={userType == 1 ? conversationMessage : clientSideMessage}
            handleFetchData={()=>viewMessage(userType ===3 ? requestDetails:conversatingUser )}
            conversation={conversation}
            products={products}
            />
          {/* <Messages/> */}

          <Divider />

          <ChatMessageInput
            addedBy={requestDetails?.added_by}
            conversatingUser={conversatingUser}
            //  conversationId={activeConversationId}
            conversationId={conversationKey}
            onSend={userType == 1 ? handleSendMessage : handleSendAdminMsg}
            disabled={pathname === PATH_DASHBOARD.client.chat}
          />
        </Box>

        {/* {mode === 'DETAIL' && <ChatRoom conversation={conversation} participants={displayParticipants} />} */}
      </Box>
    </Box>
  );
}
