import PropTypes from 'prop-types'
import { formatDistanceToNowStrict } from 'date-fns'
// material
import {
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles'
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core'
import { fDate } from 'src/utils/formatTime'
import moment from 'moment'
import { isToday } from 'date-fns/esm'
import { UserMoreMenu } from '../user/list'
import {
  CancelOutlined,
  ThumbDownOffAltOutlined,
  ThumbUpAltOutlined,
} from '@material-ui/icons'
import { useState } from 'react'
import Modal from '../blog/ProjectModel'
import { LoadingButton } from '@material-ui/lab'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'
import Label from 'src/components/Label'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}))

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}))

const MessageImgStyle = styled('img')(({ theme }) => ({
  height: 200,
  maxWidth: 296,
  width: '100%',
  cursor: 'pointer',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
}))

// ----------------------------------------------------------------------

ChatMessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  conversation: PropTypes.object.isRequired,
  onOpenLightbox: PropTypes.func,
}

export default function ChatMessageItem({
  message,
  products,
  conversation,
  handleFetchData,
  onOpenLightbox,
  ...other
}) {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  let user = JSON.parse(localStorage.getItem('user'))
  const messageDate = moment.unix(message?.timestamp)
  const isTodayMessage = isToday(messageDate.toDate())
  const [modalClose, setModalClose] = useState(false)
  const [openProductModel, setOpenProductModel] = useState(false)
  const [openReasonModal, setOpenReasonModal] = useState(false)
  const [reasonText, setReasonText] = useState('')
  let formattedDate = isTodayMessage
    ? messageDate.format('HH:mm:ss a')
    : messageDate.format('DD/MM/YYYY HH:mm:ss a')
    
  const handleUpdateDesign = (data) => {
        setModalClose(true)
    data.requestId = message.request_id
    data.userId = user.user_id
    if (data.approved) data.approved_by = user.user_id

    if (data.approved === 0 && reasonText === '') {
      enqueueSnackbar('Please add a reason', { variant: 'error' })
      return
    }
    if (!data.id) {
      enqueueSnackbar("Updating it from here is not possible.", { variant: 'error' })
      return
    }
    axios
      .post(`${REST_API_END_POINT}request/update-designs`, data)
      .then((res) => {
        if (res.data.status === 1) {
          enqueueSnackbar(
            data.approved === 1
              ? 'Design has been approved'
              : 'Design has been rejected',
            { variant: 'success' },
          )
          handleFetchData()
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' })
        }
        setModalClose(false)
        setOpenProductModel(false)
        setOpenReasonModal(false)
        setReasonText('')
      })
  }

  return (
    // <RootStyle {...other}>
    message?.image !== '' && message?.image_status !== 1 ? null : (
    <Box
      justifyContent={message.direction == 'left' ? 'flex-start' : 'flex-end'}
      sx={{
        display: 'flex',
        ml: 'auto',
        alignItems: 'center',
        marginBottom: 3,
      }}
    >
      {/* {senderDetails.type !== 'me' && (
          <Avatar alt={senderDetails.name} src={senderDetails.avatar} sx={{ width: 32, height: 32 }} />
        )}

        <Box sx={{ ml: 2 }}>
          <InfoStyle noWrap variant="caption" sx={{ ...(isMe && { justifyContent: 'flex-end' }) }}>
            {!isMe && `${firstName},`}&nbsp;
            {formatDistanceToNowStrict(new Date(message.createdAt), {
              addSuffix: true
            })}
          </InfoStyle>

          <ContentStyle
            sx={{
              ...(isMe && {
                color: 'grey.800',
                bgcolor: 'primary.lighter'
              })
            }}
          > */}
      {/* { message.image !==''&& (
              <MessageImgStyle alt="attachment" src={message.image} 
                // onClick={() => onOpenLightbox(message.body)} 
              />
            ) 
            } */}
      {/* </ContentStyle> */}
      {/* </Box> */}
      {/* {console.log(message,'@@@@@@@@@@@@@ message in item ')} */}
      <Stack
        key={message.id}
        direction="row"
        spacing={2}
        // justifyContent={message.direction === 'left' ? 'flex-start' : 'flex-end'}
        alignItems="center"
      >
        {/* <Avatar alt={message.name} src={message.profile_pic} /> */}

        <Paper
          sx={{
            p: 1.5,
            flexGrow: 1,
            maxWidth: 400,
            bgcolor:
              message.direction == 'left'
                ? 'background.neutral'
                : theme.palette.primary.dark, // 'primary.main'
            color: message.direction == 'left' && 'common.white',
            borderRadius: '12px',
          }}
        >
          {(message.image !== '' && message.image_status === 1) && (
            <Box>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  spacing={1}
                  justifyContent={'flex-start'}
                >
                  <Avatar
                    sx={{ width: 38, height: 38 }}
                    alt={message.name}
                    src={message.profile_pic}
                  />
                  {/* <Label
                    variant={'filled'}
                    color={message.approved === 1 ? 'success' : 'error'}
                    style={{ color: 'white', height: 20, width: 100 }}
                  >
                    {message.approved === 1 ? 'Approved' : 'Rejected'}
                  </Label> */}
                  {message.approved === 1 ? (
                        <Stack>
                          <Label
                            variant={'filled'}
                            color={'success'}
                            style={{ color: 'white', height: 20, width: 100 }}
                          >
                            {'Approved'}
                          </Label>
                          <Typography
                            fontSize={'0.7rem'}
                            variant={'caption'}
                            sx={{ color: 'text.primary' }}
                          >
                            Approved By: {message.approved_by}
                          </Typography>
                        </Stack>
                    ) : message.reason ? 
                    <Stack>
                      <Label
                        variant={'filled'}
                        color={'error'}
                        style={{ color: 'white', height: 20, width: 100 }}
                      >
                        {'Rejected'}
                      </Label>
                      <Typography
                        fontSize={'0.7rem'}
                        variant={'caption'}
                        sx={{ color: 'text.primary' }}
                      >
                        Rejected Reason: {message.reason}
                      </Typography>
                    </Stack> : ''}
                </Stack>
                {(!user.isVendor&&message.isVendor!==1)&&(
                <UserMoreMenu
                  common={{
                    name: message?.approved !== 1 && 'Approve',
                    function:
                    message?.approved !== 1 ? () => {
                            setModalClose(true)
                            setOpenProductModel(true)
                          } : null ,
                    Iccon:
                    message?.approved !== 1 && (
                        <ThumbUpAltOutlined />
                      ),
                    modalClose: modalClose,
                  }}
                  common2={{
                    name: (message?.approved === 1 || (message?.approved === 0 && message?.reason === null)) && 'Reject',
                    function:
                    (message?.approved === 1 || (message?.approved === 0 && message?.reason === null)) ? () => {
                            setOpenReasonModal(true)
                          } : null ,
                    Iccon:
                    (message?.approved === 1 || (message?.approved === 0 && message?.reason === null)) && (
                        <ThumbDownOffAltOutlined />
                      )
                      ,
                    modalClose: modalClose,
                  }}
                    // onDelete={() => handleUpdateDesign({ id: message.id, status: 0 })}
                  {...(message.approved === 1
                    ? {}
                    : {
                        onDelete: () =>
                          handleUpdateDesign({
                            id: message.image_id,
                            status: 0,
                          }),
                      })}
                />
                )}
              </Stack>
              <MessageImgStyle
                alt="attachment"
                src={message.image}
                // onClick={() => onOpenLightbox(message.body)}
              />
              {/* {openProductModel && (
                <Card
                  sx={{
                    position: 'absolute',
                    zIndex: 10,
                    bottom: 5,
                    left: 5,
                    p: 2,
                  }}
                >
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography marginBottom={2} variant="subtitle2">
                      Choose Product
                    </Typography>
                    <CancelOutlined
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setModalClose(false)
                        setOpenProductModel(false)
                      }}
                    />
                  </Stack>
                  <Divider sx={{ marginBottom: 2 }} />
                  <Stack spacing={1}>
                    {products.map((product) => (
                      <>
                        <Typography
                          sx={{ cursor: 'pointer' }}
                          variant="caption"
                          onClick={() =>
                            handleUpdateDesign({
                              id: message.image_id,
                              product_id: product.product_id,
                              approved: 1,
                            })
                          }
                        >
                          {product.product_name}
                        </Typography>
                        <Divider />
                      </>
                    ))}
                  </Stack>
                </Card>
              )} */}
            </Box>
          )}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent="space-between"
            spacing={1}
            sx={{ my: 0.5 }}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              spacing={1}
              justifyContent={'flex-start'}
            >
              {message.image == '' && (
                <Avatar
                  sx={{ width: 38, height: 38 }}
                  alt={message.name}
                  src={message.profile_pic}
                />
              )}
              <Typography
                variant="subtitle2"
                sx={{
                  color:
                    message.direction == 'left'
                      ? 'text.primary'
                      : 'common.white',
                }}
              >
                {message.name}
              </Typography>
            </Stack>
            <Typography
              variant="caption"
              sx={{
                color:
                  message.direction == 'left'
                    ? 'text.disabled'
                    : 'common.black',
              }}
            >
              {formattedDate}
            </Typography>
          </Stack>
          <Box width={296}>
            <Typography
              variant="body2"
              sx={{
                color:
                  message.direction === 'left'
                    ? 'text.secondary'
                    : 'text.secondary',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
              }}
            >
              {message.message}
            </Typography>
          </Box>
        </Paper>
      </Stack>

      <Modal
        open={openReasonModal}
        handleClose={() => {
          setOpenReasonModal(false)
        }}
        modalTitle={`Reason for Design Rejection`}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Reason"
              value={reasonText}
              onChange={(e) => setReasonText(e.target.value)}
            />
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'center',
                gap: 1,
              }}
            >
              <LoadingButton
                type="submit"
                variant="contained"
                onClick={() =>
                  handleUpdateDesign({
                    id: message.image_id,
                    reason: reasonText,
                    approved: 0,
                  })
                }
                // loading={isSubmitting}
              >
                {' Save '}
              </LoadingButton>
              <Button
                variant="outlined"
                type="submit"
                onClick={() => {
                  setOpenReasonModal(false)
                }}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openProductModel}
        handleClose={() => {
          setModalClose(false)
          setOpenProductModel(false)
        }}
        modalTitle={`Choose Product`}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={4}>
            <Divider sx={{ marginBottom: 2 }} />
            <Stack spacing={1}>
              {products.map((product) => (
                <>
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    variant="caption"
                    onClick={() =>
                      handleUpdateDesign({
                        id: message.image_id,
                        product_id: product.product_id,
                        reason:null,
                        approved: 1,
                      })
                    }
                  >
                    {product.product_name}
                  </Typography>
                  <Divider />
                </>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Modal>
    </Box>
    )
    // </RootStyle>
  )
}
