import * as Yup from 'yup';
// import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton, MobileDatePicker } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  Link,
  FormHelperText,
  FormControlLabel,
  Button,
  Container,
  Autocomplete
} from '@material-ui/core';

// utils
import { fData } from '../../../utils/formatNumber';
import fakeRequest from '../../../utils/fakeRequest';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs.js';
import Page from 'src/components/Page.js';
//
import Label from '../../Label';
import { UploadAvatar, UploadSingleFile } from '../../upload';
import { Link as RouterLink } from 'react-router-dom';

import countries from '../user/countries';
import { useEffect } from 'react';
import { REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import axios from 'axios';
import moment from 'moment';
import MaterialFinishedProducts from 'src/pages/dashboard/MaterialFinishedProducts';
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
import InventoryFinishedProducts from './InventoryFinishedProducts';

export default function InventoryAuditPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const prodId = parseInt(id);
  let prodData;
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme()
  const [selectedFile, setSelectedFile] = useState(null);
  const isEdit = false
  // const [taxCategories, setTaxCategories] = useState([]);
  const currentDate = moment().format('MM-DD-YYYY');
  
  // const fetchTaxCategory = async () => {
  //   try{
  //     const response = await axios.post(`${REST_API_END_POINT}product/get-tax-category`)
  //     if(response.data.status === 1){
  //       setTaxCategories(response.data.taxResult);
  //     }
  //   }
  //   catch(error){
  //     console.log("Error - ",error);
  //   }
  // }
  // useEffect(()=>{
  //   fetchTaxCategory()
  // },[])
  const fetchAllProductCategory = async () => {
    try {
      axios
        .post(REST_API_END_POINT + 'product/get-all-product-categories')
        .then((res) => {
          let data = res.data    
         if(data.status===1){
           setCategory(data.productCategories)
         }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    } catch (e) {
      console.error('Exception:', e)
    }
  }
  const getAllSubCategory=async()=>{
    try {
        axios.post(`${REST_API_END_POINT}product/get-all-sub-category`)
        .then((res)=>{
          if(res.data.status===1){
            setSubCategory(res.data.data||[])
          }
        })
        .catch((error)=>{
            console.log(error)
        })
    } catch (error) {
        console.log(error)
    }
  }
  useEffect(async () => {
    // console.log("Product id----", prodId)
    const response = await axios.post(`${REST_API_END_POINT}product/get-product-by-id?id=${prodId}`);
    if (response.data.status == 1) {
      prodData = response.data.result;
      setProduct(prodData[0]);
    }
    else {
      enqueueSnackbar('No matching data found', { variant: 'error' });
      navigate(PATH_DASHBOARD.products.material);
    }
    fetchAllProductCategory()
    getAllSubCategory()
  }, [])
  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    category:Yup.object().nullable().required('Category is required'),
    sub_category:Yup.object().nullable().required('Sub Category is required'),
    min_value: Yup.number()
    .integer()
    .required('Minimum Value is required')
    .test('is-greater', 'Max value must be greater than min value', function(value) {
      const { max_value } = this.parent;
      return value === undefined || max_value === undefined || value < max_value;
    }),
  max_value: Yup.number()
    .integer()
    .required('Maximum Value is required')
    .test('is-greater', 'Max value must be greater than min value', function(value) {
      const { min_value } = this.parent;
      return value === undefined || min_value === undefined || value > min_value;
    }),
    // avatarUrl: Yup.mixed().required('Avatar is required')
  });
  //
  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  }));
  //
  const prod_name = (product.name) ? product.name : '';
  const prod_tax = (product.tax_category) ? product.tax_category : '';
  const prod_description = (product.description) ? product.description : '';
  const prod_minVal = (product.min_value) ? product.min_value : '';
  const prod_maxVal = (product.max_value) ? product.max_value : '';
  const initialCategory = (product.category_id) ?category.find((data)=>data.id === product.category_id) :null;
  const initialSubCategory = (product.sub_category_id) ? subCategory.find((data)=>data.id === product.sub_category_id) : null;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: prod_name,
      description: prod_description,
      tax: prod_tax,
      category:initialCategory,
      sub_category:initialSubCategory,
      max_value:prod_maxVal,
      min_value:prod_minVal
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        const user = localStorage.getItem('user')
        const userData = JSON.parse(user)
        const formData = new FormData();
        formData.append("added_by", userData.user_id);
        formData.append("name", values.name);
        formData.append("description", values.description);
        formData.append("max_value", values.max_value);
        formData.append("min_value", values.min_value);
        formData.append("category", values.category.id);
        formData.append("sub_category", values.sub_category.id);
        formData.append("tax", values.tax);
        /*******************************************************************************************************/
        //Displaying formdata in console

        // console.log("Updation - Form data : ");
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ' - ' + pair[1]);
        // }
        /*******************************************************************************************************/
        try {
          const response = await axios.post(`${REST_API_END_POINT}product/update-product?id=${prodId}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          console.log("response.data : ", response.data);
          if (response.data.status === 1) {
            enqueueSnackbar('Material has been updated successfully!', { variant: 'success' });
          }
          else if ((response.data.status === 2) ||
                   (response.data.status === 0) ||
                   (response.data.status === 3) ||
                   (response.data.status === 406) ||
                   (response.data.status === 500)) {
            enqueueSnackbar(response.data.message, { variant: 'error' });
          }
          else {
            enqueueSnackbar('Server error', { variant: 'error' });
          }
        } catch (error) {
          enqueueSnackbar('Error in updating material', { variant: 'error' });
          console.error("Axios request failed:", error.message);
          if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
          } else if (error.request) {
            console.error("No response received. Request details:", error.request);
          } else {
            console.error("Error setting up the request:", error.message);
          }
        }
        resetForm();
        setSubmitting(false);
        navigate(PATH_DASHBOARD.products.material);
      } catch (error) {
        console.error("Updation error : ", error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setSelectedFile(file);
        setFieldValue('avatarUrl', {
          ...file,
          preview: URL.createObjectURL(file)
        });
      }
    },
    [setFieldValue]
  );

  const UploadImage = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  }

  const handleback = () => {
    window.history.back()
  }

  return (
    <Page title="Inventory Audit | Sign-Wise Solution">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" >
          <HeaderBreadcrumbs heading={`Material Inventory Audit - ${prod_name}`} />
          <Button
            variant="outlined"
            type="submit"
            onClick={() => handleback()}
            sx={{ alignItems: 'right' }}
            startIcon={
              <Stack>
                <KeyboardBackspaceOutlined />
              </Stack>
            }
          >
            Back
          </Button>
        </Stack>
        <InventoryFinishedProducts />
      </Container>
    </Page>
  );
}