import { Container,Stack } from '@material-ui/core';
// components
import Page from 'src/components/Page.js';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs.js';
import InventoryAuditList from './InventoryAuditList';

export default function InventoryAudit(){
    
    return(
        <Page title="Inventory Audit | Sign-Wise Solution">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
        <HeaderBreadcrumbs
          heading="Inventory Audit"
        />
        </Stack>

        <InventoryAuditList />
        
      </Container>
    </Page>
  );
}