import * as Yup from 'yup';
// import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton, MobileDatePicker } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  Link,
  FormHelperText,
  FormControlLabel,
  Button,
  Container,
  Autocomplete
} from '@material-ui/core';

// utils
import { fData } from '../../../utils/formatNumber';
import fakeRequest from '../../../utils/fakeRequest';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs.js';
import Page from 'src/components/Page.js';
//
import Label from '../../Label';
import { UploadAvatar, UploadSingleFile } from '../../upload';
import { Link as RouterLink } from 'react-router-dom';
import countries from '../user/countries';
import moment from 'moment';
import axios from 'axios';
import {REST_API_END_POINT} from '../../../constants/Defaultvalues';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from 'src/firebase/firebase'

export default function AddCommunityType({setOpenModal, editTypeId, setEditTypeId}) {
  const location = useLocation();
  const editId = parseInt(editTypeId);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme()
  const [selectedFile, setSelectedFile] = useState([]);
  const [editData, setEditData] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'))
  const userId = user.user_id

  const fetchEditData = async () => {
    try{
      // console.log("editId---------",editId);
      const results = await axios.post(`${REST_API_END_POINT}community-type/get-by-id`,{editId})
      if(results?.data?.status === 1){
        setEditData(results?.data?.result)
        // console.log("results?.data?.result------",results?.data?.result);
      }
      else{
        setEditData([])
      }
    }
    catch(error){
      console.log("Fetch data error : ",error);
    }
  }

  useEffect(()=>{
    fetchEditData();
  },[editId])

  const NewCommunitySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editData ? editData?.name : null,
    },
    validationSchema: NewCommunitySchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors, setFieldError }) => {
      try {
        let response = '';
        if(editId){
          // console.log("editId---------",editId);
          response = await axios.post(`${REST_API_END_POINT}community-type/edit`, {values,userId,editId})
        }else{
          response = await axios.post(`${REST_API_END_POINT}community-type/add`, {values,userId})
        }
        // console.log("Edit community type response : ", response.data);
        if (response.data.status === 1) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          resetForm();
          setSubmitting(false);
          setOpenModal(false)
          setEditTypeId();
          // navigate(PATH_DASHBOARD.community.type);
        }
        else if(response.data.status === 2){
          setFieldError('name',"Already exists")
        }
        else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      } catch (error) {
        console.error("Error : ", error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  return (
    <Page title={editId ? "Edit Project Type | Sign-Wise Solution" :"Add Project Type | Sign-Wise Solution"}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={8}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Name"
                      type="text"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>

                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      {!editId ? 'Add Project Type' : 'Save changes'}
                    </LoadingButton>
                    <Button variant="outlined" onClick={()=>{setOpenModal(false); setEditTypeId(); formik.resetForm();}} sx={{ marginLeft: '10px' }}>
                      Cancel
                    </Button>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
    </Page>
  );
}