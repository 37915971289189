import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Grid
} from '@material-ui/core';
// redux
import { useDispatch } from '../../redux/store';
import { getUserList, deleteUser } from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { UserListHead } from '../../components/_dashboard/user/list';
import axios from 'axios';
import { COMPANY_NAME, REST_API_END_POINT } from '../../constants/Defaultvalues';
import MaterialInventoryToolbar from 'src/components/_dashboard/user/list/MaterialInventoryToolbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'Category Name', alignRight: false },
  { id: 'value', label: 'Tax (%)', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function TaxcategoryList(props) {
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const location = useLocation();
  const receivedData = location.state?.data;
  const [taxCategories, setTaxCategories] = useState([]);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

    const fetchTaxCategory = async () => {
        setLoading(true)
        try{
            const response = await axios.post(`${REST_API_END_POINT}product/get-tax-category`)
            if(response.data.status === 1){
                setTaxCategories(response.data.taxResult);
            }
        }catch(error){
            console.log("Error - ",error);
        }
        finally{
            setLoading(false)
        }
    }
    useEffect(()=>{
        fetchTaxCategory()
    },[])

  // ...
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0)
  };

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - taxCategories.length) : 0;
  const filteredUsers = applySortFilter(taxCategories, getComparator(order, orderBy), filterName);
  const slicedData = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title={`Tax Categories | ${COMPANY_NAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
        <HeaderBreadcrumbs
          heading="Tax Categories"
        />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{mb:3}}>
            <Stack sx={{py:0.5,px:2,borderRadius:2,backgroundColor:theme.palette.error.main}}>
                <Typography sx={{color:theme.palette.text.primary}}>Please contact super admin to update tax categories</Typography>
            </Stack>
        </Stack>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={10}>
                <Card>
                <MaterialInventoryToolbar
                filterName={filterName} 
                onFilterName={handleFilterByName}
                item="Tax Categories"
                />

                <Scrollbar>
                    <TableContainer sx={{ minWidth: 500 }}>
                    <Table>
                    <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={slicedData?.length}
                        onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                        {loading ? (
                            <CircularProgress color="primary" variant="indeterminate" />
                            ) : (
                            <>
                        {slicedData?.map((row) => {
                            let { id,name, value } = row;
                            return (
                            <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                            >
                                <TableCell padding="checkbox">
                                {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id,name)} /> */}
                                </TableCell>
                                <TableCell align="center">{id}</TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                    <Typography variant="subtitle2" noWrap onClick={()=>{navigate(`${PATH_DASHBOARD.products.editMaterial}?id=${id}/edit`)}} style={{ cursor: 'pointer' }}>
                                    {name}
                                    </Typography>
                                </Stack>
                                </TableCell>
                                <TableCell align="center">{value}%</TableCell>
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </>
                            )}
                        </TableBody>
                        {isUserNotFound && (
                        <TableBody>
                            <TableRow>
                            <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterName} msg="Materials"/>
                            </TableCell>
                            </TableRow>
                        </TableBody>
                        )}
                    </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={taxCategories.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Card>
            </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
