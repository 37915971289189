import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import twitterFill from '@iconify/icons-eva/twitter-fill'
import linkedinFill from '@iconify/icons-eva/linkedin-fill'
import facebookFill from '@iconify/icons-eva/facebook-fill'
import instagramFilled from '@iconify/icons-ant-design/instagram-filled'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import { sentenceCase } from 'change-case'
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'

import {
  Box,
  Card,
  Grid,
  Avatar,
  Tooltip,
  Divider,
  Stack,
  Typography,
  IconButton,
  AvatarGroup,
} from '@material-ui/core'
// utils
import { fShortenNumber } from 'src/utils/formatNumber'
//
import SvgIconStyle from 'src/components/SvgIconStyle'
import Label from 'src/components/Label'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { Message, MessageSharp, RequestQuote } from '@material-ui/icons'
import moment from 'moment'
import { getAgoString } from 'src/common/Helper'
import { useSnackbar } from 'notistack'
// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    //  backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    // backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  },
}))

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  //    background: `url(${src}) center/cover no-repeat`
})

// ----------------------------------------------------------------------

function InfoItem(number) {
  return (
    <Grid item xs={4}>
      <Typography
        variant="caption"
        sx={{ mb: 0.5, color: 'text.secondary', display: 'block' }}
      >
        Follower
      </Typography>
      <Typography variant="subtitle1">{fShortenNumber(number)}</Typography>
    </Grid>
  )
}

OfficialCard.propTypes = {
  card: PropTypes.object.isRequired,
  team_member: PropTypes.array.isRequired,
}

export default function OfficialCard({ card, team_member, communityId,clientId, communityName, CommunityProjects }) {
  const theme = useTheme()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  let user = JSON.parse(localStorage.getItem('user'))
  const userType = user.user_type
  const explodedPermissions = user?.exploded_permissions
  const userId = user.user_id
  const {
    image,
    message,
    timestamp,
    id,
    name,
    status,
    request,
    people,
    description,
    subject,
    submission_deadline,
    msg_count,
    proposal_count,
    participation_ends,
    services,
    accept_status,
    thumbnail,
    cover_image,
    invoice_total,
    partners,
    customer_pic
  } = card
  let customer_image = partners?.filter((item)=>item?.profile_pic != 'avatar.jpg')
  let accepted_customer_image = partners?.filter((item)=>{(item?.profile_pic != 'avatar.jpg') && (item?.status == 1)})?.map((data)=>{
    return data?.profile_pic
  })
  // console.log('customer_image--',customer_image);
  // console.log('accepted_customer_image--',accepted_customer_image);
//  const currentUnix = moment().unix()
//  console.log(currentUnix,'))))))))))))))))')
//   console.log(submission_deadline,'$$$$$$$$$$$$$$$$')
  const updatedAt = moment.unix(timestamp).format('YYYY-MM-DD')
  //status color
  const getStatusColorAndLabel = (status) => {
    switch (status) {
      case 1:
        return { color: 'warning', label: 'Status: Draft' }
      case 2:
        return { color: 'info', label: 'Status: Open' }
      case 3:
        return { color: 'primary', label: 'Status: Evaluating' }
      case 4:
        return { color: 'success', label: 'Status: Accepted' }
      case 5:
        return { color: 'warning', label: 'Status: Closed' }
      case 6:
        return { color: 'error', label: 'Status: Rejected' }
      default:
        return { color: 'default', label: 'Status: Open' }
    }
  }

  const option = [
    { id: 0, value: 'Inactive' },
    { id: 1, value: 'New' },
    { id: 2, value: 'Open' },
    { id: 3, value: 'Completed' },
    { id: 4, value: 'In Testing' },
    { id: 5, value: 'Accepted' },
    { id: 6, value: 'Rejected' },
    { id: 7, value: 'Verified' },
    { id: 6, value: 'Reopen' },
  ]
  const colors = [
    'error',
    'primary',
    'secondary',
    'success',
    'warning',
    'success',
    'error',
    'success',
    'error',
  ]

  return (
    <Card
      id={id}
      onClick={() => {
        //    navigate(PATH_DASHBOARD.blog.viewproject)
      }}
    >
      <CardMediaStyle>
        {/* <IconButton
        sx={{
          top: 8,zIndex:500,color:theme.palette.grey.A100,
          right: 5,
          position: 'absolute'
        }}
      >
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton> */}
        {/* <SvgIconStyle
          color="paper"
          src={image}
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            bottom: -26,
            position: 'absolute'
          }}
        /> */}

        <AvatarGroup
          max={7}
          sx={{ '& .MuiAvatar-root': { width: 62, height: 62, zIndex: 10 } }}
        >
          {team_member?.length > 0 &&
            team_member?.map((person) => {
              return (
                <Tooltip title={person?.member_name}>
                  <Avatar
                    key={person.id}
                    sx={{
                      width: 30,
                      height: 30,
                      position: 'absolute',
                      transform: 'translateY(-50%)',
                    }}
                    alt={person?.member_name}
                    src={person?.profile_pic}
                  />
                </Tooltip>
              )
            })}
        </AvatarGroup>
        <CoverImgStyle
          alt="cover"
          src={((customer_pic && (customer_pic != 'avatar.jpg')) ? customer_pic : '/static/mock-images/avatars/avatar_default1.jpg')} //thumbnail ? thumbnail : 
          // ((accepted_customer_image?.length > 0) ? accepted_customer_image[0] : customer_image[0]?.profile_pic || '/static/mock-images/avatars/avatar_default1.jpg')} //thumbnail ? thumbnail : 
          // communityName && (cover_image ? cover_image : '/static/mock-images/avatars/avatar_default1.jpg') || 
        />
        <Typography sx={{ position: 'absolute', top: 8, left: 10, zIndex: 10 }}>
         {CommunityProjects ?
         <Label
         variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={colors[status]} >
          {option.find(opt=>opt.id===status).value} 
         </Label>
         :
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={getStatusColorAndLabel(status).color}
          >
            {getStatusColorAndLabel(status).label}
          </Label>}
        </Typography>
      </CardMediaStyle>
      {userType ==3 && accept_status ==2&& status !==6 && (
        <Typography
        color={theme.palette.error.light}
        variant="body2"
        align="center"
        // sx={{ color: 'text.secondary' }}
      >
          Sorry, You are not eligible to view the request
           because you have not accepted the request
            on or before the Evaluation Stage
      </Typography>
      
      )}
      <Typography
        onClick={
          CommunityProjects ?
          (((explodedPermissions?.some(permission => ['Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs','Manage Job Cost'].includes(permission))) || (userType == 3))
            ? () => navigate(PATH_DASHBOARD.project.viewproject+id)
            : enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' }))
          :
          (((explodedPermissions?.some(permission => ['Create Request','Edit Request','Delete request','Add vendor to request','Add client to request'].includes(permission))) || (userType === 3 && status !== 6)) && !(userType === 3 && accept_status === 2)
            ? () => navigate(`/dashboard/official-request/view/${id}`)
            : enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' }))
        }
        variant="subtitle1"
        align="center"
        sx={{
          mt: 3,
          color: theme.palette.primary.main,
          '&:hover': {
            cursor: CommunityProjects ?
            ((explodedPermissions?.some(permission => ['Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs','Manage Job Cost'].includes(permission)))
              ? 'pointer'
              : 'default' )
            :
            (((explodedPermissions?.some(permission => ['Create Request','Edit Request','Delete request','Add vendor to request','Add client to request'].includes(permission))) || (userType === 3 && status !== 6)) && !(userType === 3 && accept_status === 2)
              ? 'pointer'
              : 'default')
          },
        }}
      >
        {name}
      </Typography>

      {/* <Typography
        variant="body2"
        align="center"
        sx={{ color: 'text.secondary' }}
      >
        {description}
      </Typography> */}
      <Typography
        variant="overline"
        align="center"
        sx={{ color: 'text.secondary' }}
      ></Typography>

      {/* <Divider /> */}

      <Typography
        variant="subtitle1"
        align="center"
        sx={{ color: 'text.secondary', mt: 2 }}
      >
       Last updated {getAgoString(timestamp)}
      </Typography>
      <Grid
        container
        sx={{
          textAlign: 'center',
          justifyContent: 'flex-end',
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          pb: 2,
          pr: 2,
        }}
      >
        {invoice_total ?  
          <Typography
            variant="body2"
            align="center"
            sx={{ color: 'text.secondary' }}
          >
            <RequestQuote sx={{ width: 20, height: 20, color:status===4?theme.palette.success.main:theme.palette.error.main}} />
            {invoice_total}
          </Typography>
        : null }
      </Grid>
    </Card>
  )
}
