import Modal from "../_dashboard/blog/ProjectModel";
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Edit } from '@material-ui/icons';
import { UploadAvatar, UploadSingleFile } from 'src/components/upload';
import { fData } from 'src/utils/formatNumber';
import { storage } from 'src/firebase/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import axios from 'axios';
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import moment from 'moment'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import { Box, Card, Grid, Stack,Typography,Button, TextField, FormControl, FormLabel, Select, MenuItem, Autocomplete, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { LoadingButton, MobileDatePicker } from "@material-ui/lab";
import { useCallback } from "react";
import { sentenceCase } from "change-case";

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    //   marginBottom: theme.spacing(1)
  }));
  const LabelStyleHead = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.secondary,
  }))

export default function EditViewTemplate ({
    openEdit ,
    setOpenEdit,
    templateData,
    label,
    fetchTemplateData
}){
    const user = localStorage.getItem('user')
    const userData = JSON.parse(user)
    const theme = useTheme(); 
    const { enqueueSnackbar } = useSnackbar()
    const NewUserSchema = Yup.object().shape({
        
      })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: templateData?.reduce((acc, curr) => {
            acc[curr.content] = curr.value;
            return acc;
        }, {}),

        validationSchema: NewUserSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
         try {
          const formData = Object.keys(values).map(key => {
            const fieldData = templateData.find(item => item.content === key);
            return {
                ...fieldData,
                value: values[key]
            };
        });
          axios.post(`${REST_API_END_POINT}installation/update-inst-template`,{formData})
          .then((res)=>{
            if(res.data.status==1){
              enqueueSnackbar(res.data.message,{variant:'success'})
              setOpenEdit(false)
              fetchTemplateData()
            }else{
              enqueueSnackbar(res.data.message,{variant:'error'})
            }
          })
         } catch (error) {
            
         }
        }
          
      })
      const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
      console.log("values----",values);
      const handleDropFile = useCallback(
        async (acceptedFiles, field_id, content) => {
          try {
            if (acceptedFiles && acceptedFiles.length > 0) {
              const file = acceptedFiles[0]
              const filename = new Date().getTime() + file.name
    
              const storageRef = ref(storage, filename)
              const uploadTask = uploadBytesResumable(storageRef, file)
    
              uploadTask.on(
                'state_changed',
                (snapshot) => {
                  const newProgress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                  )
                },
                (error) => {
                  console.error('Error during upload:', error)
                },
                async () => {
                  const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
                 
                  setFieldValue(content,downloadURL)
                },
              )
            }
          } catch (error) {
            console.error('Unexpected error:', error)
          }
        },
        [setFieldValue],
      )
      const parseContent = (htmlContent) => {
        const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
        return doc.body.textContent || "";
      };
      const rightContentStyle = { color: '#1ccaff' };
    return(
      <Modal open={openEdit} handleClose={()=>{setOpenEdit(false)}} 
        modalTitle={label==="edit_template" ?'Edit Report':'View Report'} >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} alignItems={'center'} justifyContent={'center'}>
              <Grid item xs={12} md={8}>
                {/* <Card sx={{ p: 3 }}>  */}
                  <Stack spacing={3}>
                    {label !=='view_template' && templateData?.map((data)=>{
                          const {name,content,field_id,installation_id,value} = data
                          switch (name) {                                                  
                              case 'textarea':
                                  return (                                    
                                  <TextField
                                  key={value.id}
                                  label={<div 
                                    style={{ whiteSpace: 'pre-wrap' }}
                                      dangerouslySetInnerHTML={{ __html: content }} />}                                     
                                  fullWidth
                                  id={'text'}                                                                        
                                    value={values[content]}
                                  onChange={(event) =>
                                    setFieldValue(content,event.target.value)
                                  }                                    
                                  sx={{
                                    width: '100%',
                                    marginTop: '8px',
                                    padding: '8px',
                                  }}
                                />
                                  )
                              case 'text':
                                  return (
                                    <TextField
                                    key={value.id}
                                    label={<div 
                                      style={{ whiteSpace: 'pre-wrap' }}
                                        dangerouslySetInnerHTML={{ __html: content }} />}                                     
                                    fullWidth
                                    id={'text'}                                      
                                      value={values[content]}
                                    onChange={(event) =>
                                      setFieldValue(content,event.target.value)
                                    }
                                    sx={{
                                      width: '100%',
                                      marginTop: '8px',
                                      padding: '8px',
                                    }}
                                  />
                                  )
                              case 'date':
                                return (
                                  <Box
                                    key={content}
                                    component={MobileDatePicker}
                                    label={<div 
                                      style={{ whiteSpace: 'pre-wrap' }}
                                        dangerouslySetInnerHTML={{ __html: content }} />}
                                    placeholder={<div 
                                      style={{ whiteSpace: 'pre-wrap' }}
                                        dangerouslySetInnerHTML={{ __html: content }} />}                                                                               
                                    value={values[content]}
                                    onChange={(date) =>setFieldValue(content,moment(date).format('YYYY-MM-DD'))}
                                    renderInput={(params) => (
                                      <TextField                                          
                                        size="small"
                                        {...params}
                                        fullWidth
                                      />
                                    )}
                                  />
                                )
                              case 'number':
                                return (                                  
                                  <TextField
                                    key={value.id}
                                    label={<div 
                                      style={{ whiteSpace: 'pre-wrap' }}
                                        dangerouslySetInnerHTML={{ __html: content }} />}
                                    type="number"
                                    fullWidth
                                    value={values[content]}
                                    onChange={(event) =>setFieldValue(content,event.target.value)}
                                  />                                     
                                )
                              case 'select':
                                return (
                                  <FormControl>                                   
                                  <FormLabel  id="demo-select-label">
                                    {<div 
                                    style={{ whiteSpace: 'pre-wrap' }}
                                      dangerouslySetInnerHTML={{ __html: content }} />}
                                  </FormLabel>             
                                  <Select
                                  label={<div 
                                    style={{ whiteSpace: 'pre-wrap' }}
                                      dangerouslySetInnerHTML={{ __html: content }} />}
                                      placeholder={<div 
                                    style={{ whiteSpace: 'pre-wrap' }}
                                      dangerouslySetInnerHTML={{ __html: content }} />}
                                      defaultValue={values[content]}
                                      value={values[content]}
                                    onChange={(event,newValue) =>setFieldValue(content,newValue.props.children)}
                                  >
                                    {console.log('values[content]------',values[content]) }
                                    {value?.options?.map((data) => (
                                      <MenuItem
                                        key={data.label}
                                        value={data.label}
                                      >
                                        {data.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  </FormControl>
                                )
                              case 'file':
                                return (
                                  <>
                                    <LabelStyle>
                                      <div 
                                      style={{ whiteSpace: 'pre-wrap' }}
                                        dangerouslySetInnerHTML={{ __html: content }} /></LabelStyle>
                                    <UploadSingleFile
                                      key={content}
                                      maxSize={3145728}
                                      accept="image/*"
                                      file={values[content]}
                                      onDrop={(acceptedFiles) =>
                                        handleDropFile(
                                          acceptedFiles,
                                          field_id,
                                          content,
                                        )
                                      }
                                      />
                                  </>
                                )
                              case 'autocomplete':
                                return (
                                  <FormControl>
                                    <FormLabel id="demo-autocomplete-label"
                                    >  
                                      <div 
                                      style={{ whiteSpace: 'pre-wrap' }}
                                        dangerouslySetInnerHTML={{ __html: content }} />                           
                                    </FormLabel>                                     
                                    <Autocomplete
                                      fullWidth
                                      id="combo-box-demo"
                                      defaultValue={values[content]}
                                      value={values[content]}
                                      options={(value?.options || [])?.map(option => option.label)}
                                      getOptionLabel={(option)=>option}
                                      onChange={(event,newValue) =>{
                                        setFieldValue(content,newValue)
                                      }  
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}/>
                                      )}    
                                      />
                                  </FormControl>
                                )
                              case 'radio-group':
                                return (
                                  <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">
                                      <div 
                                      style={{ whiteSpace: 'pre-wrap' }}
                                        dangerouslySetInnerHTML={{ __html: content }} />
                                    </FormLabel>
                                    <RadioGroup
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      name="radio-buttons-group"
                                      value={values[content]}
                                      onChange={(event) =>setFieldValue(content,event.target.defaultValue) }
                                    >
                                      {value?.options?.map((data) => (
                                        <FormControlLabel
                                          key={data.value}
                                          value={data.label}
                                          control={<Radio/>}
                                          label={data.label}
                                        />
                                      ))}
                                    </RadioGroup>
                                  </FormControl>
                                )
                                case 'checkbox-group':
                                  return (
                                    <FormControl>
                                    <FormLabel id="demo-autocomplete-label"
                                    >                                       
                                      <div 
                                      style={{ whiteSpace: 'pre-wrap' }}
                                        dangerouslySetInnerHTML={{ __html: content }} />                           
                                    </FormLabel>                                     
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="combo-box-demo"
                                      defaultValue={values[content]}
                                      value={values[content]}
                                      options={(value?.options || [])?.map(option => option.label)}
                                      getOptionLabel={(option)=>option}
                                      onChange={(event,newValue) =>{setFieldValue(content,newValue)
                                      }  
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}/>
                                      )}    
                                      />
                                  </FormControl>
                                  );
                              default:
                                return null
                            }
                    })}
                    {label ==='view_template' && templateData?.map((data)=>{
                          const {name,content,field_id,installation_id,value} = data
                          console.log('name--------------',name);
                          switch (name) {
                            case 'header':
                              return (
                                <Stack
                                  direction={'row'}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                  spacing={3}
                                  marginLeft={2}
                                  padding={3}
                                >
                                  {/* <Typography variant="subtitle2">{sentenceCase(tag)}</Typography> */}
                                  <Typography variant="subtitle2" sx={rightContentStyle}>{content ? sentenceCase(parseContent(content)) : ""}</Typography>
                                </Stack>
                              );
                            case 'paragraph':
                            case 'textarea':
                            case 'text':
                            case 'number':
                            case 'autocomplete':
                            case 'select':
                            case 'checkbox-group':
                            case 'radio-group':
                              return (
                                <Stack
                                  direction={'row'}
                                  alignItems={'center'}
                                  justifyContent={'flex-start'}
                                  spacing={3}
                                  // marginLeft={2}
                                >
                                  <Typography variant="subtitle2">{content ? `${sentenceCase(parseContent(content))} : ` : ""}</Typography>
                                  <Typography variant="subtitle2" sx={rightContentStyle}>{value}</Typography>
                                </Stack>
                              );
                            case 'file':
                              return (
                                <Stack
                                  direction={'row'}
                                  justifyContent={'flex-start'}
                                  spacing={3}
                                  marginLeft={2}
                                >
                                  <Typography variant="subtitle2">{sentenceCase(parseContent(content))+' : '}</Typography>
                                  <Stack direction="row" spacing={2} alignItems="center">
                                    {value && (
                                      <img
                                        src={value}
                                        alt={parseContent(content)}
                                        style={{
                                          width: '120px',
                                          height: '120px',
                                          borderRadius: '5px',
                                          objectFit: 'fill'
                                        }}
                                      />
                                    )}
                                  </Stack>
                                </Stack>
                              );
                        
                            case 'date':
                              return (
                                <Stack
                                  direction={'row'}
                                  alignItems={'center'}
                                  justifyContent={'flex-start'}
                                  spacing={1}
                                >
                                  <Typography variant="subtitle2">{sentenceCase(parseContent(content))}</Typography>
                                  <Typography variant="subtitle2" sx={rightContentStyle}>{value}</Typography>
                                </Stack>
                              );
                            default:
                              return null;
                          }
                    })}
                    {label !=='view_template' && (                            
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}  >
                          Save and Publish
                        </LoadingButton>
                      
                        <Button variant="outlined"  onClick={()=>{setOpenEdit(false)}}
                          >
                          Cancel
                        </Button>
                      </Box> 
                    )} 
                  </Stack>
                {/* </Card> */}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Modal>
    )
}