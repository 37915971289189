import { sentenceCase } from 'change-case';
import {
     Container,
     Stack,Button, 
     
    } from '@material-ui/core';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { PATH_DASHBOARD } from 'src/routes/paths.js';
import { Link as RouterLink } from 'react-router-dom';
import Page from 'src/components/Page.js';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs.js';
import ReceiveOrderList from 'src/pages/dashboard/ReceiveOrderList';

export default function ReceiveOrders({clientId}){
  const userData = JSON.parse(localStorage.getItem('user'))
  const explodedPermissions =userData?.exploded_permissions
    
    return(
    <Page title="Receive Inventory Orders | Sign-Wise Solution">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
        <HeaderBreadcrumbs
          heading="All Orders"
        />
        {clientId ? '' :<Button
            disabled={explodedPermissions?.some(permission => ['Create Order'].includes(permission)) ? false : true}
            variant="contained"
            sx={{ width: 'fit-content' , marginBottom: '20px'}}
            startIcon={<Icon icon={plusFill} />}
            component={RouterLink}
            to={PATH_DASHBOARD.receiveOrder.create}
          >
          Create Order
          </Button>}
        </Stack>
        <ReceiveOrderList clientId={clientId}/>
        
      </Container>
    </Page>
  );
}