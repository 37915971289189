// routes
import { PATH_DASHBOARD, ROOTS_PCB } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

import { AssignmentInd, BusinessCenter, Groups, SupportAgent, AccountBox, RequestQuote, LocalShipping, PrecisionManufacturing, Dashboard, ManageAccounts,GroupWork, People, SupervisedUserCircle, Folder, Inventory } from '@material-ui/icons';
import useWorkSpaceOption from 'src/hooks/useWorkspaceOptions';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  // dashboard: getIcon('ic_dashboard'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban'),
  project:getIcon('project-manager')
};

const Title = JSON.parse(localStorage.getItem("user")) || 'Jobs'

let JOB_TITLE= Title?.job_title ? Title?.job_title :'Jobs'
// const { workSpaceTitle } = useWorkSpaceOption()
const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     {
  //       title: 'app',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard
  //     },
  //     { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics }
  //   ]
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     // { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
      //   ]
      // },

      {
        title: 'Production control board',
        path: ROOTS_PCB,
        name:'Production control board',
        icon: <Dashboard />,
        type: 3,
        //ICONS.dashboard,
        // children: [
        //   { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar }
        // ]
      },
      
      {
        title: 'Installation Calendar',
        name:'Installation Calendar',
        path: PATH_DASHBOARD.calendar,
        icon: ICONS.calendar,

        // children: [
        //   { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar }
        // ]
      },
      {
        title: 'call center',
        name:'call center',
        path: PATH_DASHBOARD.callCenter.root,
        icon: ICONS.user,

        children: [
          { title: 'incoming window', path: PATH_DASHBOARD.callCenter.incomingWindow },
          { title: 'analytics', path: PATH_DASHBOARD.callCenter.analytics },
          { title: 'account setup', path: PATH_DASHBOARD.callCenter.accountSetup },
          { title: 'call logs', path: PATH_DASHBOARD.callCenter.callLogs },
          { title: 'sms logs', path: PATH_DASHBOARD.callCenter.smsLogs },
          { title: 'recordings', path: PATH_DASHBOARD.callCenter.recordings },
          { title: 'monitor calls', path: PATH_DASHBOARD.callCenter.monitorCalls },
          { title: 'call settings', path: PATH_DASHBOARD.callCenter.callSettings },
        ]
      },
      //official request
      {
        title: 'official request',
        name:'official request',
        path: PATH_DASHBOARD.official.root,
        icon: <RequestQuote />,
        type: 3,
      },
       {
        title: JOB_TITLE,
        name:"job_client",
        path: PATH_DASHBOARD.project.root,
        icon: ICONS.blog,
       },
      // MANAGEMENT : BLOG
      {
        title: JOB_TITLE,
        name:JOB_TITLE,
        path: PATH_DASHBOARD.project.root,
        icon: ICONS.blog,
        children: [
          { title: `All ${JOB_TITLE}`, path: PATH_DASHBOARD.project.all },
          { title: `${JOB_TITLE} Type`, path: PATH_DASHBOARD.project.type },
          { title: `${JOB_TITLE} Category`, path: PATH_DASHBOARD.project.category }
        ]
      },

      {
        title: 'Installation',
        name:'installation',
        path: PATH_DASHBOARD.installation.root,
        icon: <PrecisionManufacturing />,
        children: [
          { title: 'Installation', path: PATH_DASHBOARD.installation.root },
          { title: 'Installation Template', path: PATH_DASHBOARD.installation.template },
        ]
      },
      {
        title: 'Purchase',
        name:'purchase',
        icon: <Inventory />,
        children: [
          { title: 'All Vendors', path: PATH_DASHBOARD.client.vendors},
          { title: 'Tax categories', path: PATH_DASHBOARD.taxCategory.root },
          { title: 'Receive Inventory Orders', path: PATH_DASHBOARD.receiveOrder.root },
        ]
      },

      // ONBOARDING
      {
        title: 'Staff Management',
        name:'Staff Management',
        // path: PATH_DASHBOARD.onboard.root,
        icon: <ManageAccounts />,

        children: [
          { title: 'Departments', path: PATH_DASHBOARD.callCenter.departments },
          { title: 'Team Members', path: PATH_DASHBOARD.callCenter.teams },
          { title: 'User Roles', path: PATH_DASHBOARD.adminAccount.userRole },
          { title: 'Time Tracking', path: PATH_DASHBOARD.staffTime.root },
          { title: 'Staff Payout', path: PATH_DASHBOARD.staffPayout.root },
          { title: 'Staff Scheduler', path: PATH_DASHBOARD.staffSchedular.root },
          
        ]
      },
      {
        title: 'Workflow',
        name:"Onboarding",
        path: PATH_DASHBOARD.onboard.root,
        icon: <GroupWork />,
        type: 3,

        children: [
          { title: 'Workflow', path: PATH_DASHBOARD.onboard.root },
          { title: 'Production Workflow', path: PATH_DASHBOARD.onboard.productWorkflow },
          { title: 'Custom Field Template', path: PATH_DASHBOARD.customfieldtemplate.root },
          { title: 'Questionnaire Templates', path: PATH_DASHBOARD.QuestionnaireTemplates.root },
          { title: 'Sign Agreements', path: PATH_DASHBOARD.signagreements.root },
          
        ]
      },
      {
        title: 'Onboarding',
        name:"OnboardingClient",
        path: PATH_DASHBOARD.onboard.process,
        icon: <AssignmentInd />,
        type: 3,
      },

      //CRM
      {
        title: 'CRM',
        name:'CRM',
        path: PATH_DASHBOARD.crm.root,
        icon: <SupportAgent />,

        children: [
          { title: 'Contacts', path: PATH_DASHBOARD.crm.contact },
          { title: 'Leads', path: PATH_DASHBOARD.crm.lead },
          { title: 'Opportunities', path: PATH_DASHBOARD.crm.opportunity },
          { title: 'Calls', path: PATH_DASHBOARD.crm.call },
          { title: 'SMS', path: PATH_DASHBOARD.crm.sms },
          { title: 'Tickets', path: PATH_DASHBOARD.crm.ticket },
          { title: 'Campaigns', path: PATH_DASHBOARD.crm.campaign },
        ]
      },

      // MANAGEMENT : USER
      {
        title: 'clients',
        name:'clients',
        path: PATH_DASHBOARD.client.cards,
        icon: <AccountBox />,
        // children: [
        //   { title: 'all clients', path: PATH_DASHBOARD.client.cards },
        //   // { title: 'list', path: PATH_DASHBOARD.user.list },
        //   // { title: 'create', path: PATH_DASHBOARD.user.newUser },
        //   // { title: 'edit', path: PATH_DASHBOARD.user.useredit },
        //   // { title: 'account', path: PATH_DASHBOARD.user.profile },
        //   // { title: 'profile', path: PATH_DASHBOARD.user.account }
        // ]
      },
      // {
      //   title: 'vendors',
      //   name:'vendors',
      //   path: PATH_DASHBOARD.client.vendors,
      //   icon: <SupervisedUserCircle/>,
      // },
      // {
      //   title: 'Insights',
      //   name:'Insights',
      //   path: PATH_DASHBOARD.insight.root,
      //   icon: <BusinessCenter />,

      // },
      // {
      //   title: 'Groups',
      //   name:'Groups',
      //   path: PATH_DASHBOARD.group.root,
      //   icon: <Groups />,

      // },
      // {
      //   title: 'Payments',
      //   name:'Payments',
      //   path: PATH_DASHBOARD.payments.root,
      //   icon: ICONS.ecommerce,

      // },


      {
        title: 'Inventory',
        name:'Inventory',
        path: PATH_DASHBOARD.products.root,
        icon: ICONS.analytics,
        children:[
          { title: 'Catalog', path: PATH_DASHBOARD.products.main },
          { title: 'Services', path: PATH_DASHBOARD.products.labour },
          { title: 'Material Inventory', path: PATH_DASHBOARD.products.material },
          { title: 'Inventory Audit', path: PATH_DASHBOARD.inventoryAudit.root },
          { title: 'Product Formula', path: PATH_DASHBOARD.products.recipe },
          { title: 'Category', path: PATH_DASHBOARD.products.category },
          { title: 'Sub Category', path: PATH_DASHBOARD.products.subcategory },
        ]
      },

      {
        title: 'Trafficwise',
        name:'Trafficwise',
        path: PATH_DASHBOARD.transportation.root,
        icon: <LocalShipping />,

        children: [
          { title: 'Transportation', path: PATH_DASHBOARD.transportation.root },
          { title: 'Routes', path: PATH_DASHBOARD.transportation.routes },
          { title: 'Route Optimaization', path: PATH_DASHBOARD.transportation.routeoptimization },
          { title: 'Drivers', path: PATH_DASHBOARD.transportation.drivers },
          { title: 'Vehicles', path: PATH_DASHBOARD.transportation.vehicles },
          { title: 'Track Drivers', path: PATH_DASHBOARD.transportation.track },
        ]
      },
      {
        title: 'Customer Orders',
        name:'Customer Orders',
        path: PATH_DASHBOARD.customerOrders.root,
        icon: ICONS.cart,

      },
      {
        title: 'Projects',
        name:'Projects',
        path: PATH_DASHBOARD.community.root,
        icon: ICONS.project,

      },



      // {
      //   title: 'Profile',
      //   path: PATH_DASHBOARD.user.account,
      //   // icon: ICONS.user,
      //   // children: [
      //   //   { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar }
      //   // ]
      // },

      // {
      //   title: 'products',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     // { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
      //   ]
      // },


    ]
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     // { title: 'mail', path: PATH_DASHBOARD.mail.root, icon: ICONS.mail },
  //     // { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     // { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban }
  //   ]
  // }
];

export default sidebarConfig;
