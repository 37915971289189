import { io } from 'socket.io-client';
import { REST_API } from 'src/constants/Defaultvalues';

export default class SocketClient {
  socket

  connect(subscriber_id,user_id) {
    this.socket = io(REST_API ) // { query: `subscriber_id=${subscriber_id}&user_id=${user_id}` }
    return new Promise((resolve, reject) => {
      this.socket.on('connect', () => resolve({mesage:'connection success'}))
      this.socket.on('connect_error', (error) => reject(error))
    })
  }

  disconnect() {
    return new Promise((resolve) => {
      this.socket.disconnect(() => {
        this.socket = null
        console.log('socet desconnect ongoing in child');
        resolve()
      })
    })
  }

  emit(event, data) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject('No socket connection.')

      return this.socket.emit(event, data, (response) => {
        // Response is the optional callback that you can use with socket.io in every request. See 1 above.
        if (response.error) {
          console.error(response.error)
          return reject(response.error)
        }
        return resolve()
      })
    })
  }

  on(event, fun) {
    // No promise is needed here, but we're expecting one in the middleware.
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject('No socket connection.')

      this.socket.on(event, fun)
      resolve()
    })
  }

  off(event, litseners) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject('No socket connection.')

      this.socket.off(event, litseners)
      resolve()
    })
  }
}
