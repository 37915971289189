import * as Yup from 'yup';
// import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton, MobileDatePicker } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  Link,
  FormHelperText,
  FormControlLabel,
  Button,
  Container,
  Autocomplete,
  IconButton,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';

// utils
import { fData } from '../../../utils/formatNumber';
import fakeRequest from '../../../utils/fakeRequest';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs.js';
import Page from 'src/components/Page.js';
//
import Label from '../../Label';
import { UploadAvatar, UploadSingleFile } from '../../upload';
import { Link as RouterLink } from 'react-router-dom';
import countries from '../user/countries';
import moment from 'moment';
import axios from 'axios';
import {REST_API_END_POINT} from '../../../constants/Defaultvalues';
import { Delete, KeyboardBackspaceOutlined } from '@material-ui/icons';
import Scrollbar from 'src/components/Scrollbar';

const VALUE = [
  { id: 0, value: 'Material ID' },
  { id: 1, value: 'Materials' },
  { id: 2, value: 'Unit Cost' },
  { id: 3, value: 'Quantity' },
  { id: 4, value: 'Sub total' },
  { id: 5, value: '' }
]

export default function EditReceiveOrders() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const orderId = searchParams.get('id');
  const [vendors, setVendors] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const currentDate = moment().format('MM-DD-YYYY');
  const userData = JSON.parse(localStorage.getItem('user'))
  const userId = userData.user_id
  const userType = userData.user_type
  let totalAmount = 0;
  
  const handleback = () => {
    window.history.back()
  }

  const handleProduct = (value) => {
    const productsWithQuantity = value.map((product) => ({
      ...product,
      qty: selectedProducts.filter((p) => p.id === product.id)[0]?.qty || 0,
      unit_cost: selectedProducts.filter((p) => p.id === product.id)[0]?.unit_cost || 0,     
    }));
    // console.log('productsWithQuantity*****',productsWithQuantity);
    setSelectedProducts(productsWithQuantity);
  };


  const calculateTotal = (price, qty) => {
    const parsedPrice = parseFloat(price) || 0;
    const parsedQuantity = parseFloat(qty) || 0;
    const subtotal = parsedPrice * parsedQuantity;
    return subtotal;
  };
  const calculateGrandTotal = (subtotal) => {
  const includeTax = values.includeTax === 1;
  let taxAmount = 0;
  if (includeTax) {
    taxAmount = subtotal * taxPercentage / 100;
  }
  return subtotal + taxAmount;
};

  const handleDeleteProduct = (productId) => {
    const filteredProduct = selectedProducts.filter(data => data.id !== productId);
    setFieldValue('products', filteredProduct);
    setSelectedProducts(filteredProduct);

    let newTotalAmount = 0;
    filteredProduct.forEach(product => {
      const subtotal = product.unit_cost * product.qty;
      newTotalAmount += subtotal;
    });

    setFieldValue('sub_total', newTotalAmount);
    setFieldValue('grand_total', calculateGrandTotal(newTotalAmount));
  };

  const LabelStyleHead = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.primary,
  }))
  
  const fetchVendors = async () => {
    try{
      const response = await axios.post(`${REST_API_END_POINT}clients/getAllClients`,{userId,userType,isVendor:1})
      if(response.data.status === 1){
        const vendors = response?.data?.customer
        setVendors(vendors);
      }
    }
    catch(error){
      console.log("Error - ",error);
    }
  }
  const fetchTaxCategory = async () => {
    try{
      const response = await axios.post(`${REST_API_END_POINT}product/get-tax-category`)
      if(response.data.status === 1){
        const tax = response.data.taxResult?.find((option) => option.name = 'tax')
        if(tax){
          setTaxPercentage(tax.value);
        }
        else{
          setTaxPercentage(0);
        }
      }
    }
    catch(error){
      console.log("Error - ",error);
    }
  }

  const fetchProducts = async () => {
    try{
      const response = await axios.post(`${REST_API_END_POINT}product/get-all-materials`)
      if(response.data.status === 1){
        setProductsData(response.data.results);
      }
    }
    catch(error){
      console.log("Error - ",error);
    }
  }

  const fetchOrderDataById = async () => {
    try{
      const response = await axios.post(`${REST_API_END_POINT}product/get-order-by-id`,{orderId})
      if(response.data.status === 1){
        setEditData(response.data.prodData);
        setSelectedProducts(response.data.prodData)
      }
    }
    catch(error){
      console.log("Error - ",error);
    }
  }

  useEffect(()=>{
    fetchVendors();
    fetchTaxCategory()
    fetchProducts()
    if(orderId){
        fetchOrderDataById()
    }
  },[orderId])

  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    vendor: Yup.string().required('Vendor name is required'),
    receivedDate: Yup.date(),
    expectedDate: Yup.date(),
    transactionId: Yup.string().required('Transaction ID is required'),
    products: Yup.array().min(1,'Please select atleast one product'),
  });
  
  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  }));
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editData[0]?.order_name || '',
      vendor: editData[0]?.vendor_id || '',
      transactionId: editData[0]?.transaction_id || '',
      receivedDate: editData[0]?.received_date && moment.unix(editData[0]?.received_date).format('MM-DD-YYYY') || null,
      expectedDate: editData[0]?.expected_date && moment.unix(editData[0]?.expected_date).format('MM-DD-YYYY') || null,
      includeTax: editData[0]?.include_tax || 0,
      products: editData || [],
      tax: editData[0]?.tax || 0,
      sub_total: editData[0]?.sub_total || 0,
      grand_total: editData[0]?.grand_total || 0,
    },
    validationSchema: NewProductSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        values.products = selectedProducts
        // console.log('selectedProducts-------',selectedProducts);
        const response = await axios.post(`${REST_API_END_POINT}product/edit-receive-order`,{values,userId,orderId})
        if (response.data.status === 1) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
        }
        else {
          enqueueSnackbar('Failed to update order', { variant: 'error' });
        }
        resetForm();
        setSubmitting(false);
        navigate(PATH_DASHBOARD.receiveOrder.root);
      } catch (error) {
        console.error("Error : ", error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const updateGrandTotal = () => {
    const subtotal = values.sub_total || 0;
    const grandTotal = calculateGrandTotal(subtotal);
    setFieldValue('grand_total', grandTotal);
  };
  
  // Update grand total whenever sub_total changes
  useEffect(() => {
    updateGrandTotal();
  }, [values.sub_total]);

  const handleSwitchChange = (event) => {
    const includeTax = event.target.checked;
    let taxAmount = 0;
    if (includeTax) {
      taxAmount = values.sub_total * taxPercentage / 100;
    }
    const grandTotal = values.sub_total + taxAmount;
    setFieldValue('includeTax', includeTax ? 1 : 0);
    setFieldValue('tax', includeTax ? taxPercentage : 0);
    setFieldValue('grand_total', grandTotal);
  };

  return (
    <Page title="Edit Order | Sign-Wise Solution">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <HeaderBreadcrumbs heading=" Edit Order" />
          <Button
            variant="outlined"
            type="submit"
            onClick={() => handleback()}
            sx={{ alignItems: 'right' }}
            startIcon={
              <Stack>
                <KeyboardBackspaceOutlined />
              </Stack>
            }
          >
            Back
          </Button>
        </Stack>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item xs={12} md={10}>
                <Card sx={{ p: 10, margin: 'auto',height: 'fit-content' }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                            fullWidth
                            label="Name"
                            type="text"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                        />
                        <Autocomplete
                            select
                            fullWidth
                            id="vendor"
                            options={vendors}
                            getOptionLabel={(option) => option.name}
                            value = {vendors?.find((option) => option.id === values.vendor) || null}
                            onChange={(event, newValue) =>{
                                if(newValue){
                                    const selectedOption = vendors?.find(option => option.id === newValue.id)
                                    if(selectedOption){
                                    setFieldValue('vendor', newValue?.id);
                                    }else {
                                    setFieldValue('vendor', '');
                                    }
                                }
                                else {
                                    setFieldValue('vendor', '')
                                }
                            }}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select vendor"
                                fullWidth
                                SelectProps={{ native: true }}
                                error={Boolean(touched.vendor && errors.vendor)}
                                helperText={touched.vendor && errors.vendor}
                            />
                            )}
                        />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                        fullWidth
                        label="Transaction ID"
                        {...getFieldProps('transactionId')}
                        error={Boolean(touched.transactionId && errors.transactionId)}
                        helperText={touched.transactionId && errors.transactionId}
                    />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <Box
                        component={MobileDatePicker}
                        label="Date Received"
                        maxDate={currentDate}
                        value={values.receivedDate}
                        onChange={(date) => setFieldValue('receivedDate', date)}
                        renderInput={(params) => (
                          <TextField size="medium" {...params} fullWidth />
                        )}
                      />
                      <Box
                        component={MobileDatePicker}
                        label="Date Expected"
                        minDate={values.expectedDate || currentDate}
                        value={values.expectedDate}
                        onChange={(date) => setFieldValue('expectedDate', date)}
                        renderInput={(params) => (
                          <TextField size="medium" {...params} fullWidth />
                        )}
                      />
                    </Stack>
                    <Divider />
                    <LabelStyleHead>Materials</LabelStyleHead>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <Autocomplete
                          fullWidth
                          multiple
                          id="combo-box-demo"
                          // options={productsData || []}
                          options={productsData.filter(product => !selectedProducts.some(selected => selected.id === product.id)) || []}
                          value={values.products}
                          onChange={(event, newValue) => {
                            if (!newValue || newValue.length === 0) {
                              setFieldValue('products', newValue);
                              setSelectedProducts([]);
                              setFieldValue('sub_total', 0);
                              setFieldValue('grand_total', 0);
                            } else {
                              setFieldValue('products', newValue);
                              handleProduct(newValue);
                            }
                          }}
                          getOptionLabel={(option) => option.name ? option.name : ''}
                          // getOptionSelected={(option, value) => option.material_id === value.material_id}
                          // isOptionEqualToValue={(option, value) => option.material_id === value.material_id}
                          renderInput={(params) => (
                            <TextField {...params} required fullWidth
                            error={Boolean(touched.products && errors.products)}
                            helperText={touched.products && errors.products} 
                            label="Select material" />
                          )}
                        />
                      </Stack>
                      {(selectedProducts?.length > 0) &&
                      <>
                        <LabelStyle>Selected Materials</LabelStyle>
                        <Scrollbar>
                          <TableContainer sx={{ minWidth: 500 }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {VALUE?.map((val) => (
                                    <TableCell key={val.id}>{val.value}</TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedProducts?.map((data, index) => {
                                  const quantityFieldName = `quantity_${index}`;
                                  const unitCostFieldName = `unitCost_${index}`;

                                  // const rowTotal = calculateTotal(values[unitCostFieldName], values[quantityFieldName]);
                                  const rowTotal = calculateTotal(data.unit_cost, data.qty);
                                  // Accumulate the totalAmount
                                  totalAmount += rowTotal;
                                  values['sub_total'] = totalAmount
                                  return (
                                    <>
                                    <TableRow key={data.id}>
                                      <TableCell>{data.id}</TableCell>
                                      <TableCell>{data.name}</TableCell>
                                      {/* <TableCell>{data.unit_cost}</TableCell> */}
                                      <TableCell>
                                        <TextField required
                                          fullWidth
                                          type='number'
                                          label="Unit Cost"
                                          value={Number(data.unit_cost)}
                                          onKeyPress={(event) => {
                                            // console.log('errorFieldId------',errorFieldId)
                                            if (event.key === '0' && event.target.value === '0' ) {
                                              event.preventDefault();
                                            }
                                            if (event.key === '0' && event.target.value === '' ) {
                                              event.preventDefault();
                                            }
                                            if (event.key === '-' ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            let newValue = event.target.value;
                                            if (newValue !== "0" && newValue !== "") {
                                              data['unit_cost'] = newValue || 0;
                                              setFieldValue(unitCostFieldName, newValue);
                                            }
                                          }}
                                          error={Boolean(touched[unitCostFieldName] && errors[unitCostFieldName])}
                                          helperText={touched[unitCostFieldName] && errors[unitCostFieldName]}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <TextField required
                                          fullWidth
                                          type='number'
                                          label="Quantity"
                                          value={Number(data.qty)}
                                          onKeyPress={(event) => {
                                            // console.log('errorFieldId------',errorFieldId)
                                            if (event.key === '0' && event.target.value === '0' ) {
                                              event.preventDefault();
                                            }
                                            if (event.key === '0' && event.target.value === '' ) {
                                              event.preventDefault();
                                            }
                                            if (event.key === '.' ) {
                                              event.preventDefault();
                                            }
                                            if (event.key === '-' ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            const newValue = event.target.value;
                                            if (newValue !== "0" && newValue !== "") {
                                              if (newValue === '' || newValue <= 0) {
                                                event.preventDefault();
                                                return;
                                              }
                                              if (newValue.startsWith('-')) {
                                                newValue = newValue.slice(1);
                                              }
                                              data['qty'] = newValue || 0;
                                              setFieldValue(quantityFieldName, newValue);
                                            }
                                          }}
                                          error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                          helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                        />
                                      </TableCell>
                                      <TableCell> 
                                        {rowTotal}
                                        {/* {calculateTotal(values[unitCostFieldName], values[quantityFieldName])} */}
                                      </TableCell>
                                      <TableCell >
                                        <IconButton onClick={() => handleDeleteProduct(data.id)}>
                                          <Delete />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                      </>}

                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={handleSwitchChange}
                                    checked={values.includeTax === 1}
                                    defaultChecked
                                />
                            }
                            label="Include Tax"
                        />
                      </Box>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Sub Total"
                          {...getFieldProps('sub_total')}
                          error={Boolean(touched.sub_total && errors.sub_total)}
                          helperText={touched.sub_total && errors.sub_total}
                          InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                          InputProps={{ readOnly:true }}
                        />
                        <TextField
                          fullWidth
                          label="Tax (%)"
                          {...getFieldProps('tax')}
                          error={Boolean(touched.tax && errors.tax)}
                          helperText={touched.tax && errors.tax}
                          InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                          InputProps={{ readOnly:true }}
                        />
                        <TextField
                          fullWidth
                          label="Grand Total"
                          {...getFieldProps('grand_total')}
                          error={Boolean(touched.grand_total && errors.grand_total)}
                          helperText={touched.grand_total && errors.grand_total}
                          InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                          InputProps={{ readOnly:true }}
                        />
                      </Stack>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Save Changes
                      </LoadingButton>
                      <Button variant="outlined" onClick={()=>{handleback()}} sx={{ marginLeft: '10px' }}>
                        Cancel
                      </Button>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}