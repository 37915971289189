import * as Yup from 'yup'
import { Button, Container, Grid, Stack, Card,IconButton, Input, InputAdornment, TableContainer, Table, TableBody, TableRow, TableCell, TablePagination, Typography, TextField, } from '@material-ui/core';
import Page from '../../components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { useCallback, useEffect, useState } from 'react';
import { Box, Toolbar } from '@mui/material'
import Icon from '@iconify/react'
import searchFill from '@iconify/icons-eva/search-fill'
import outlineClose from '@iconify/icons-ic/outline-close';
import {
    experimentalStyled as styled,
} from '@material-ui/core/styles'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import CreateTagModal from 'src/components/_dashboard/contact_tag/CreatetagModal';
import { COMPANY_NAME, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import TagTable from 'src/components/_dashboard/contact_tag/TagTable';
import { UserListHead, UserMoreMenu } from 'src/components/_dashboard/user/list';
import SearchNotFound from 'src/components/SearchNotFound';
import Scrollbar from 'src/components/Scrollbar';
import LoadingScreen from 'src/components/LoadingScreen';
import moment from 'moment';
import { Form, FormikProvider, useFormik } from 'formik'
import { LoadingButton } from '@material-ui/lab'
import Modal from 'src/components/_dashboard/blog/ProjectModel';

const TABLE_HEAD = [
    { id: 'name', label: 'Setting Name', alignRight: false },
    { id: 'time', label: 'Time (In minutes)', alignRight: false },
    { id: 'updated_by_name', label: 'Updated By', alignRight: false },
    { id: 'updated_at', label: 'Updated On', alignRight: false },
    { id: 'options', label: '', alignRight: false},      
];

const Search = styled(Input)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    fontSize: 15,
    boxShadow: theme.shadows[5],
}))

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
    borderRadius: `16px !important`,
}))
export default function ClockInOutSettings(){
    const [open,setOpen] = useState(false)
    const {enqueueSnackbar} = useSnackbar()
    const [loading, setLoading] = useState(true)
    const [editData,setEditData] = useState(null)
    const [settingsData, setSettingsData] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    let user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.user_id
    const NewUserSchema = Yup.object().shape({
        time: Yup.number().typeError('Please enter a valid time').required('Time is required'),
      })
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
          time: editData ? editData?.time : ''
        },
        validationSchema: NewUserSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
          try {           
            const  res = await axios.post(`${REST_API_END_POINT}settings/edit-clock-settings`, 
            {values,userId,editId:editData?.id})
            resetForm();
            setSubmitting(false);
            fetchData()
            enqueueSnackbar(res.data.message, { variant: res.data.status==1 ? 'success' : 'error' });
            setEditData(null)
            setOpen(false)
          } catch (error) {
            console.log('edit-clock-settings error : ',error);
            setSubmitting(false);
            setErrors(error);
          }
        },
      })
      const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
      
    function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        if (query) {
          return filter(array, (_user) =>{
            const nameMatch = _user.name && _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
            return nameMatch;
          });
        }
        return stabilizedThis.map((el) => el[0]);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
      
    const fetchData = async() => {
        try {
            const res = await axios.post(`${REST_API_END_POINT}settings/fetch-all-clock-settings`)
            if(res.data.status === 1){
                setSettingsData(res.data.clocksettings)
            }else{
                console.log(res.data);
                setSettingsData([])
            }
        } catch (error) {
            console.log("Clock settings error : ",error)
            setSettingsData([])
        } finally{
            setLoading(false);
        }
    }

    useEffect(()=>{
        fetchData()
    },[])

    const fetchEditData = async(id) => {
        try {
            const res = await axios.post(`${REST_API_END_POINT}settings/fetch-clock-settings-by-Id`,{id})
            if(res.data.status === 1){
                setEditData(res.data.clocksettings[0])
            }else{
                console.log(res.data);
                setEditData(null)
            }
        } catch (error) {
            console.log("Clock settings",error)
        } finally{
            setLoading(false);
        }
    }

    const handleEdit = async(id) => {
        setOpen(true)
        setEditData(id)
        fetchEditData(id)
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - settingsData.length) : 0;
    const filteredUsers = applySortFilter(settingsData, getComparator(order, orderBy), filterName);
    const slicedData = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const isUserNotFound = filteredUsers.length === 0;

    return(
        <Page title={`Clock Settings | ${COMPANY_NAME}`}>
            <Container  maxWidth="xl">
                <Grid container spacing={3} sx={{ pb: 4 }}>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{ justifyContent: 'flex-start', display: 'flex' }}
                    >
                        <HeaderBreadcrumbs heading={'Clock Settings'} />
                    </Grid>
                </Grid>
                <Card>
                    <Box height={15}>
                    </Box>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <UserListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={slicedData?.length}
                            // numSelected={selected?.length}
                            // onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                        {loading ? (
                            <LoadingScreen
                            sx={{ top: 0,
                                    left: 0,
                                    width: 1,
                                    zIndex: 9999,
                                    position: 'fixed',
                                    }}
                            />
                        ) : (
                            <>
                            {slicedData?.map((row) => {                                
                            let { id,name,time,updated_by,updated_by_name,updated_at } = row;
                            let updatedOn = moment.unix(updated_at).format('MM/DD/YYYY');
                            // const isItemSelected = selected.indexOf(id) !== -1;
                            return (
                                <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                // selected={isItemSelected}
                                // aria-checked={isItemSelected}
                                >
                                <TableCell >
                                    {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id,name)} /> */}
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                    <Typography variant="subtitle2" noWrap>
                                        {name}
                                    </Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell align="center">{time}</TableCell>
                                <TableCell align="center">{updated_by_name}</TableCell>
                                <TableCell align="center">{updatedOn}</TableCell>
                                <TableCell align="center">
                                    <UserMoreMenu onEdit={()=>handleEdit(id)} />
                                </TableCell>
                                </TableRow>
                            );
                            })}
                            {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                            )}
                            </>
                        )}
                        </TableBody>
                        {isUserNotFound && (
                        <TableBody>
                            <TableRow>
                            <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterName}/>
                            </TableCell>
                            </TableRow>
                        </TableBody>
                        )}
                    </Table>
                    </TableContainer>
                </Scrollbar>         

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={slicedData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Card>
            </Container>
            <Modal
                modalTitle={'Edit Clock Setting'}
                open={open}
                handleClose={() => {
                    setEditData(null)
                    setOpen(false)
                }}
                >
                <FormikProvider value={formik}>
                    <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Grid
                        container
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                        spacing={3}
                    >
                        <Grid item xs={12} md={8}>
                        <Stack spacing={3}>
                            <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 3, sm: 2 }}
                            >
                            <TextField
                                fullWidth
                                label={editData?.name}
                                placeholder='Enter time in minutes'
                                onKeyPress={(event) => {
                                    if (event.key === '0' && event.target.value === '0' ) {
                                      event.preventDefault();
                                    }
                                    if (event.key === '0' && event.target.value === '' ) {
                                      event.preventDefault();
                                    }
                                    if (event.key === '-' ) {
                                      event.preventDefault();
                                    }
                                    if (event.key === '.' ) {
                                        event.preventDefault();
                                    }
                                  }}
                                {...getFieldProps('time')}
                                error={Boolean(touched.time && errors.time)}
                                helperText={touched.time && errors.time}
                            />
                            </Stack>
                            <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 1,
                            }}
                            >
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                // onClick={() => {
                                //   setAdd(false)
                                // }}
                                loading={isSubmitting}
                            >
                                Save
                            </LoadingButton>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                setEditData(null)
                                setOpen(false)
                                }}
                            >
                                Cancel
                            </Button>
                            </Box>
                        </Stack>
                        </Grid>
                    </Grid>
                    </Form>
                </FormikProvider>
            </Modal>
        </Page>
    )
}