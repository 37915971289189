import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Grid
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getUserList, deleteUser } from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user/list';
import  CustomerOrderToolbar  from 'src/components/_dashboard/user/list/CustomerOrderToolbar';
import  ProductsMenu  from 'src/components/_dashboard/user/list/ProductsMenu.js';
import axios from 'axios';
import {COMPANY_NAME, REST_API, REST_API_END_POINT} from '../../constants/Defaultvalues';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import ConfirmationPopup from 'src/components/_dashboard/official-request/ConfirmationPopup';
import OfficialCard from 'src/components/_dashboard/official-request/OfficialCard';
import CommunityCard from 'src/pages/dashboard/CommunityCard'
import ClientProjectCard from './ClientProjectCard';
import { KeyboardArrowLeft, KeyboardBackspaceOutlined } from '@material-ui/icons';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'logo', label: 'Logo', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'addedby', label: 'Added By', alignRight: false },
  { id: 'addedon', label: 'Added On', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'options', label: '', alignRight: false},      
];

// id,name,description	,	type,phone,email,address, thumbnail, status, authorized_by, timestamp
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function ClientProjectList(props) {
    const location = useLocation();
    const clientId = location.state && location.state.id;
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [communityList, setCommunityList] = useState([]);
  const [openDeletePop,setOpenDeletePop] =useState(false);
  const [deletingId,setDeletingId] = useState();
  const [deletingName,setDeletingName] = useState('');
  const user = JSON.parse(localStorage.getItem('user'))
  const userId = user.user_id
  const userType = user.user_type
  const receivedData = location.state?.data;

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  const fetchData = async () => {
    setLoading(true)
    try{
    //   const response = await axios.post(`${REST_API_END_POINT}community/get-all-communities`)
    const response = await axios.post(`${REST_API}webservice/rfq/get-project-by-client`,{clientId, userId, userType})
      const communityListData = response?.data?.data;
      if(communityListData){
        setCommunityList(communityListData);
        // console.log("communityList : ",communityList);
      }
      else{
        setCommunityList([]);
      }
    }
    catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      setLoading(false);
    }
  }
  useEffect( async () => {
      fetchData();
  }, [receivedData]);

  // ...
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const changeStatus = async (comId, comStatus) => {
    try {
      let deleteId = comId;
      let status = comStatus;
      let response = await axios.post(`${REST_API_END_POINT}community/delete-community`,{deleteId,status,userId});
    //   console.log("response-----------------", response.data);
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        navigate(PATH_DASHBOARD.community.root, { state: { data: `${response.data.message}+${deleteId}` } });
      }
      else if (response.data.status === 0) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
        navigate(PATH_DASHBOARD.community.root, { state: { data: `${response.data.message}+${deleteId}` } });
      }
      else {
        console.log("Error.........!");
        enqueueSnackbar("Server Error...!", { variant: 'error' });
        navigate(PATH_DASHBOARD.community.root, { state: { data: `${response.data.message}+${deleteId}` } });
      }
    }
    catch (error) {
      console.log("Delete error : ", error);
    }
  }

  const handleMenuClick = async (id, status) => {
    let newStatus;
    (status === 1) ? newStatus = 0 : newStatus = 1
    // console.log("id, newStatus",id, newStatus);
    await changeStatus(id, newStatus);
  }

  const handleDeleteClick = async (id, newStatus) => {
    // console.log("id, newStatus",id, newStatus);
    setDeletingName('');
    setDeletingId();
    setOpenDeletePop(false);
    await changeStatus(id, newStatus);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0)
  };

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId));
  };



  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - communityList?.length) : 0;
  const filteredUsers = applySortFilter(communityList, getComparator(order, orderBy), filterName);
  const slicedData = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isUserNotFound = filteredUsers?.length === 0;

  return (
    <Page title={`All Projects | ${COMPANY_NAME}`}>
      <Container>
        {/* <Card> */}
        <Button
        // sx={{ background: theme.palette.primary.main, boxShadow: 'none' }}
        size="small"
        component={RouterLink}
        to={PATH_DASHBOARD.client.cards}
        variant="outlined"
        startIcon={
            <Stack>
              <KeyboardBackspaceOutlined />
            </Stack>
          }
      >
        Back to All Clients
      </Button>
          <CustomerOrderToolbar 
            filterName={filterName} 
            onFilterName={handleFilterByName} 
            item="project"
          />

          <Scrollbar>
            {loading ?
              <CircularProgress color="primary" variant="indeterminate" />
            :
            <Grid container spacing={3}>
              {filteredUsers?.length>0 && filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                // console.log("row-----------------------",row);
                return (
                  <Grid key={row.id} item xs={12} sm={6} md={4}>
                    <ClientProjectCard card={row} clientUserId={clientId} key={row.id} />
                  </Grid>
                )
              }
              )}
            </Grid>
            }
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[6, 12, 27]}
            component="div"
            count={filteredUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        {/* </Card> */}
      </Container>

      {/* Confirmation for deleting community */}
      <ConfirmationPopup
        open={openDeletePop}
        handleClose={()=>{setOpenDeletePop(false)}}
        message={`You want to delete ${deletingName} ! `}
        handleClick={()=>{handleDeleteClick(deletingId, 2)}}
      />
    </Page>
  );
}
