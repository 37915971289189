import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Button, Stack, Menu, List, MenuItem, ListItemText } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import ConfirmationPopup from '../../official-request/ConfirmationPopup';
import { useRef, useState } from 'react';
import { sentenceCase } from 'change-case'
import { ExpandLess, ExpandMore } from '@material-ui/icons';

// ----------------------------------------------------------------------

const sourceData = [
  {id:0,name:'Inventory'},
  {id:1,name:'Vendor'},
  {id:3,name:'Clear filter'},
]
const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

MaterialInventoryToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function MaterialInventoryToolbar({ numSelected, filterName, onFilterName,filterSource,onFilterSource,selected,onDelete,selectedName,item,rowCount,userId }) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDeletePop,setOpenDeletePop] = useState(false);
  const [communitiesOpen,setCommunitiesOpen] = useState(false);
  const [deleteConfirmationMessage,setDeleteConfirmationMessage] = useState()
  const refCommunities = useRef(null);

  const changeStatus = async (deleteId, deleteStatus) => {
    try {
      let id = deleteId;
      let status = deleteStatus;
      let response;
      // console.log("id-----", id, " status-----------", status, "item---------",item)
      if(item === "contacts"){
        response = await axios.post(`${REST_API_END_POINT}contacts/delete-contact`,{contactId:id,status})
      }
      else if(item === "products"){
        response = await axios.post((`${REST_API_END_POINT}product/delete-product?id=${id}&status=${status}`))
      }
      else if(item === "opportunities"){
        response = await axios.post(`${REST_API_END_POINT}opportunities/delete-opportunity`,{deleteId:id,status,userId})
      }
      else if(item === "Materials"){
        response = await axios.post((`${REST_API_END_POINT}product/delete-product?id=${id}&status=${status}`))
      }
      // console.log("response-----------------", response.data);
      return response?.data;
    }
    catch (error) {
      console.log("Delete error : ", error);
    }
  }

  const handleMenuItemClick = async (selectedIds, newStatus) => {
    try{
      setOpenDeletePop(false);
      const result = await Promise.all(selectedIds.map((id) => changeStatus(id, newStatus)));
      // console.log("deleted data length",result.length);
      if (selectedIds.length === result.length){
        onDelete();
        enqueueSnackbar(`${result.length} ${item} deleted`, { variant: 'success' });
        if(item === "contacts"){
          navigate(PATH_DASHBOARD.crm.contact, { state: { data: result } });
        }
        else if(item === "products"){
          navigate(PATH_DASHBOARD.products.material, { state: { data: result } });
        }
        else if(item === "opportunities"){
          navigate(PATH_DASHBOARD.crm.opportunity, { state: { data: result}});
        }
        else if(item === "Materials"){
          navigate(PATH_DASHBOARD.products.material, { state: { data: result } });
        }
      }
      else {
        console.log("Error.........!");
        enqueueSnackbar(`Unable to delete the ${item}.!`, { variant: 'error' });
      }
    }
    catch(error){
      console.log("Server error",error);
    }
  }

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? '#05191f' : '#05191f'
        })
      }}
    >

       {(item=="finished products") ? <></> : <SearchStyle
        sx={{backgroundColor:'#05191f'}}
          value={filterName}
          onChange={onFilterName}
          placeholder= {`Search ${sentenceCase(item)}...`}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />}
      {numSelected > 0 && (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      )}
      {item === "finished products" &&
      <>
      {/* <Stack alignItems={'center'} justifyContent={'flex-end'}> */}
        <Button ref={refCommunities} onClick={() => setCommunitiesOpen(prevState => !prevState)} 
          // variant="outlined"
          type="submit"
          // sx={{ alignItems: 'flex-end', mb: 4 }}
          endIcon={
            <Stack>
              {communitiesOpen ? <ExpandLess sx={{ ml: 'auto' }} /> : <ExpandMore sx={{ ml: 'auto' }} />}
            </Stack>
          }
        >
          {(filterSource!= '' && filterSource!= 'Clear filter')  ? filterSource : 'Filter Source'}
        </Button>
        <Menu
          open={Boolean(communitiesOpen)}
          anchorEl={refCommunities.current}
          onClose={() => setCommunitiesOpen(false)}
          PaperProps={{
            sx: { width: 'auto', maxWidth: '100%' }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
            {sourceData?.map((type) => (
              <MenuItem key={type.id} onClick={(e)=>{setCommunitiesOpen(false); onFilterSource(e.target.textContent)}}>
                <ListItemText primary={`${type.name}`} />
              </MenuItem>
            ))}
          </List>
        </Menu>
        {/* </Stack> */}
      </>}

      {numSelected > 0 && (
        <Tooltip title="Delete" onClick={()=>{setOpenDeletePop(true);  
          if (numSelected === rowCount){
          setDeleteConfirmationMessage(" all ")
        }else if (numSelected){
          setDeleteConfirmationMessage(` ${numSelected} `)
        }
        }}>
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      )}

      {/* Confirmation for deleting multiple */}
      <ConfirmationPopup
        open={openDeletePop}
        handleClose={()=>{setOpenDeletePop(false)}}
        message={`You want to delete ${deleteConfirmationMessage} ${item} ! `}
        // message={`You want to delete : ${selectedName.join(', ')} !`}
        handleClick={() => handleMenuItemClick(selected, 2)}
      />

    </RootStyle>
  );
}
