import { Box, Button, DialogActions, Typography, useTheme } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import { Dialog, DialogTitle, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import axios from 'axios'
import { Form, FormikProvider, useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { useMemo } from 'react'
import * as Yup from "yup";
import Modal from '../blog/ProjectModel'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'

function CreateTagModal({ open, onClose, isEdit, id,fetchData }) {
    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user.user_id
    const colors = useMemo(() => ['#00c2ff', '#ce011f', '#ec7618', '#f3be04', '#00c392', '#01707b', '#6662ce', '#373f51'])
    const { enqueueSnackbar } = useSnackbar()
    const [data, setData] = useState([])
    const TagSchema = Yup.object().shape({
        name: Yup.string().required("Name is required")
    })
    const fetchTagData =async () => {
        if (id) {
            try {
                const response = await axios.post(`${REST_API_END_POINT}contact-tag/get-data-by-id`, { id });
                setData(response.data.data);
            } catch (error) {
                console.log(error);
            }
        }
    };
    useEffect(() => {
        fetchTagData();
    }, [id]);

    const formik = useFormik({
        initialValues: {
            name: data? data[0]?.name: '',
            color: data? data[0]?.color: '#00c2ff',
            variant: data? data[0]?.variant:'filled',         
        },
        validationSchema: TagSchema,
        enableReinitialize: true,
        onSubmit: (values,{resetForm}) => {
            
         let promise = isEdit ? axios.post(`${REST_API_END_POINT}contact-tag/update`,{values,id}) :
            axios.post(`${REST_API_END_POINT}contact-tag/create`,{values,userId})
            promise.then((res)=>{
                if(res.data.status===1){
                    resetForm()
                    fetchData()
                    enqueueSnackbar(res.data.message,{variant:'success'})
                }else{
                    enqueueSnackbar(res.data.message,{variant:'error'})
                }
                resetForm()
                onClose()
            })
        }
    })
    const { handleSubmit, getFieldProps, isValid, errors, touched, values, resetForm } = formik
    
    const handleClose = useCallback(
        () => {
            setData()
            resetForm()
            onClose()
        },
        [onClose, resetForm],
    )

    return (
        <Modal
            open={open}
            handleClose={handleClose}
            modalTitle={`${isEdit ? "Edit" : "Create"} Tags`}
        >
            <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                    <Typography mb={2} variant='subtitle1'>
                       {isEdit?"Edit your tag":"Create a custom tag for you"}
                    </Typography>
                    <TextField
                        {...getFieldProps('name')}
                        label='Tag Name'
                        size='small'
                        fullWidth
                        placeholder='i.e. Builder, Luxury, Platinum Club'
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                    />
                    <Typography my={2} variant='subtitle1'>
                        Tag Appearance
                    </Typography>
                    <Typography mb={2} variant='subtitle2'>
                        Colors
                    </Typography>
                    <RadioGroup
                        aria-labelledby="product-color-attribute"
                        {...getFieldProps('color')}
                        sx={{ gap: 2, flexWrap: 'wrap', flexDirection: 'row' }}
                    >
                        {colors.map(
                            (color) => (
                                <Box
                                    key={color}
                                    sx={{
                                        position: 'relative',
                                        width: 38,
                                        height: 38,
                                        flexShrink: 0,
                                        bgcolor: [values.variant === "outlined" ? "white" : color],
                                        borderRadius: '100%',
                                        display: 'flex',
                                        border: `2px solid${color}`,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Radio
                                        overlay
                                        variant="solid"
                                        // color={'info'}
                                        sx={{
                                            width: 40,
                                            height: 40,
                                        }}
                                        checkedIcon={<Done
                                            sx={{ color: values.variant === "outlined" ? color : 'white' }}
                                            fontSize="large" />}
                                        value={color} icon={<></>}
                                    />
                                </Box>
                            ),
                        )}
                    </RadioGroup>
                    <Typography mt={2} variant='subtitle2'>
                        Variants
                    </Typography>
                    <RadioGroup
                        aria-labelledby="product-color-attribute"
                        sx={{ gap: 2, flexWrap: 'wrap', flexDirection: 'row' }}
                        {...getFieldProps('variant')}
                    >
                        <FormControlLabel value="filled" control={<Radio />} label="Filled" />
                        <FormControlLabel value="outlined" control={<Radio />} label="Outlined" />
                    </RadioGroup>
                    <DialogActions sx={{ justifyContent: 'flex-end' }}>
                        <Button variant='outlined' disabled={!isValid} type='submit'> 
                            {isEdit ? "Edit" : "Create"}
                        </Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Modal >
    )
}

export default CreateTagModal