import PropTypes from 'prop-types'
// material
import { Paper, Typography } from '@material-ui/core'

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
}

export default function SearchNotFound({ searchQuery = '', msg, ...other }) {
  //isProduct, isTeam, request, roles, isSkill
  return (
    <>
      {searchQuery ? (
        <Paper {...other}>
          <Typography gutterBottom align="center" variant="subtitle1">
            Not found
          </Typography>
          <Typography variant="body2" align="center" noWrap={false}>
            No results found for &nbsp;
            <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos
            or using complete words.
          </Typography>
        </Paper>
      ) : ( msg ? (
        <Paper {...other}>
          <>
            <Typography gutterBottom align="center" variant="subtitle1">
              No {msg} Found
            </Typography>
          </>
        </Paper>
      ) : null)}
    </>
  )
}
