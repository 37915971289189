export const GOOGLE_API_KEY = "AIzaSyBe6vrykAsiKjwIHWOUcfH1Li4VT-2LHTg";
// export const REST_API_END_POINT = "http://localhost:4000/webservice/";
// export const REST_API = "http://localhost:4000/";
// export const BASE_URL = "http://localhost:3000/";

// //dev.signwisesolutions.com
// export const BASE_URL = "https://dev.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://dev-api.signwisesolutions.com/webservice/";
// export const REST_API = "https://dev-api.signwisesolutions.com/";

// my.signwisesolutions.com
export const REST_API_END_POINT = "https://api.signwisesolutions.skyniche.website/webservice/";
export const REST_API = "https://api.signwisesolutions.skyniche.website/";
export const BASE_URL = "https://my.signwisesolutions.com/";
const user =  JSON.parse(localStorage.getItem('user'))

export const COMPANY_LOGO = user?.company_logo
export const COMPANY_NAME = user?.company_name

export const UNITS = [{id:1,name:'Square feet'}, {id:2,name:'Lineal feet'}, {id:3,name:'Square Inches'}, {id:4,name:'Unit'}]