import faker from 'faker';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import micFill from '@iconify/icons-eva/mic-fill';
import roundSend from '@iconify/icons-ic/round-send';
import attach2Fill from '@iconify/icons-eva/attach-2-fill';
import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Input, Divider, IconButton, InputAdornment, Stack, CircularProgress } from '@material-ui/core';
//
import EmojiPicker from '../../EmojiPicker';
import { ref as imageref, uploadBytesResumable,getDownloadURL } from '@firebase/storage';
import { storage } from 'src/firebase/firebase';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 56,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  paddingLeft: theme.spacing(2)
}));

// ----------------------------------------------------------------------

ChatMessageInput.propTypes = {
  disabled: PropTypes.bool,
  conversationId: PropTypes.string,
  onSend: PropTypes.func
};

export default function ChatMessageInput({ disabled,conversatingUser,addedBy, conversationId, onSend, ...other }) {
  let user = JSON.parse(localStorage.getItem('user'))
  const requestId = localStorage.getItem('requestId')
  const userType=user.user_type
  const userId=user.user_id
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState('')
  const [message, setMessage] = useState('');
  const [image,setImage] = useState('')
  const [progress,setProgress] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const handleAttach = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
     console.log(file,'-----------files')
     setSelectedFileName(file?.name)
     const Filename = new Date().getTime() + file?.name;

      const StorageRef = imageref(storage, Filename);
      const uploadTask = uploadBytesResumable(StorageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const pro = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(true)
        },
        (error) => {
          console.error(error.message);
          setProgress(false)   
          enqueueSnackbar('Please upload image less than 3 MB', { variant: 'error' });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
           console.log(downloadURL,'-------------downloadURL')    
           setImage(downloadURL)
           setProgress(false)   
          });
        }
      );
  }
  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      handleSend();
    }
  };
 
  const handleSend = () => {
    if (!message&&!image) {
      return '';
    }
    if (onSend) {
      onSend({
        userId:userId,
        requestId:requestId,
        isVendor:user.isVendor,
        msg: message,
        contentType: 'text',
        image:image,
        createdAt: new Date(),
        to:userType == 1 ?conversatingUser.id:addedBy,
        userType:userType,

      });
    }
            setImage('');
            setSelectedFileName('')
    return setMessage('');
     
  };

  return (
    <RootStyle {...other}>
      <Input
        disabled={disabled}
        fullWidth
        value={message}
        disableUnderline
        onKeyUp={handleKeyUp}
        onChange={handleChangeMessage}
        placeholder={"Type here"}
        startAdornment={
          <InputAdornment position="start">
                   {image && (
                      <img src={image} alt="Attached Image" style={{ maxWidth: '50px', maxHeight: '50px', marginLeft: '10px' }} />
                    )}
            {/* <EmojiPicker disabled={disabled} value={message} setValue={setMessage} /> */}
          </InputAdornment>
        }
        endAdornment={
          <Stack direction="row" spacing={0.5} mr={1.5}>
            <IconButton disabled={disabled} size="small" onClick={handleAttach}>
              <Icon icon={roundAddPhotoAlternate} width={24} height={24} />
            </IconButton>
            {/* <IconButton disabled={disabled} size="small" onClick={handleAttach}>
              <Icon icon={attach2Fill} width={24} height={24} />
            </IconButton> */}
            {/* <IconButton disabled={disabled} size="small">
              <Icon icon={micFill} width={24} height={24} />
            </IconButton> */}
            <EmojiPicker disabled={disabled} value={message} setValue={setMessage} /> 
          </Stack>
        }
        sx={{ height: '100%' }}
      />

      <Divider orientation="vertical" flexItem />

      {userType === 1 ? (
         conversatingUser?.id && (
           <IconButton color="primary" disabled={!message&&!image} onClick={handleSend} sx={{ mx: 1 }}>
            {progress?
            (
              <CircularProgress sx={{width:24,height:24}} color="secondary"
              variant="indeterminate"/>
            ):(
             <Icon icon={roundSend} width={24} height={24} />
             )}
             </IconButton>
             )
        ) : (
           addedBy && (
           <IconButton color="primary" disabled={!message&&!image} onClick={handleSend} sx={{ mx: 1 }}>
             {progress?
            (
              <CircularProgress sx={{width:24,height:24}} color="secondary"
              variant="indeterminate"/>
            ):(
             <Icon icon={roundSend} width={24} height={24} />
             )}
           </IconButton>
           )
         )}
         
       <input type="file" ref={fileInputRef}  onChange={handleFileChange} style={{ display: 'none' }} />
    </RootStyle>
  );
}
