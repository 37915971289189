import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getUserList, deleteUser } from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user/list';
import  CustomerOrderToolbar  from 'src/components/_dashboard/user/list/CustomerOrderToolbar';
import  ProductsMenu  from 'src/components/_dashboard/user/list/ProductsMenu.js';
import axios from 'axios';
import {COMPANY_NAME, REST_API, REST_API_END_POINT} from '../../constants/Defaultvalues';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import ConfirmationPopup from 'src/components/_dashboard/official-request/ConfirmationPopup';
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
import Modal from 'src/components/_dashboard/blog/ProjectModel';
import AddCommunityType from 'src/components/_dashboard/blog/AddCommunityType';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'addedby', label: 'Added By', alignRight: false },
  { id: 'addedon', label: 'Added On', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'options', label: '', alignRight: false},      
];

// id,name,description	,	type,phone,email,address, thumbnail, status, authorized_by, timestamp
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function CommunityType(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [communityType, setCommunityType] = useState([]);
  const [openDeletePop,setOpenDeletePop] =useState(false);
  const [deletingId,setDeletingId] = useState();
  const [deletingName,setDeletingName] = useState('');
  const [openModal,setOpenModal] = useState(false);
  const [editTypeId,setEditTypeId] = useState();

  const user = JSON.parse(localStorage.getItem('user'))
  const userId = user.user_id
  const explodedPermissions = user?.exploded_permissions
  const location = useLocation();
  const receivedData = location.state?.data;

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  const fetchData = async () => {
    setLoading(true)
    try{
      const response = await axios.post(`${REST_API_END_POINT}community-type/get-all`)
      const communityTypeData = response?.data?.results;
      if(communityTypeData){
        setCommunityType(communityTypeData);
        // console.log("communityType : ",communityType);
      }
      else{
        setCommunityType([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      setLoading(false);
    }
  }
  useEffect( async () => {
    try {
      fetchData();
    }
      catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [receivedData, openModal]);

  // ...
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const changeStatus = async (comId, comStatus) => {
    try {
      let deleteId = comId;
      let status = comStatus;
      let response = await axios.post(`${REST_API_END_POINT}community-type/delete`,{deleteId,status,userId});
    //   console.log("response-----------------", response.data);
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        navigate(PATH_DASHBOARD.community.type, { state: { data: `${response.data.message}+${deleteId}` } });
      }
      else if (response.data.status === 0) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
        navigate(PATH_DASHBOARD.community.type, { state: { data: `${response.data.message}+${deleteId}` } });
      }
      else {
        console.log("Error.........!");
        enqueueSnackbar("Server Error...!", { variant: 'error' });
        navigate(PATH_DASHBOARD.community.type, { state: { data: `${response.data.message}+${deleteId}` } });
      }
    }
    catch (error) {
      console.log("Delete error : ", error);
    }
  }

  const handleMenuClick = async (id, status) => {
    let newStatus;
    (status === 1) ? newStatus = 0 : newStatus = 1
    // console.log("id, newStatus",id, newStatus);
    await changeStatus(id, newStatus);
  }

  const handleDeleteClick = async (id, newStatus) => {
    // console.log("id, newStatus",id, newStatus);
    setDeletingName('');
    setDeletingId();
    setOpenDeletePop(false);
    await changeStatus(id, newStatus);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0)
  };

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId));
  };

  const handleback = () => {
    // window.history.back()
    navigate(PATH_DASHBOARD.community.root)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - communityType?.length) : 0;
  const filteredUsers = applySortFilter(communityType, getComparator(order, orderBy), filterName);
  const slicedData = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isUserNotFound = filteredUsers?.length === 0;

  return (
    <Page title={`Project Type: List | ${COMPANY_NAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Button sx={{ mb :2 }}
                variant="outlined"
                type="submit"
                onClick={() => handleback()}
                startIcon={<KeyboardBackspaceOutlined />}
            >
                Back
            </Button>
            <HeaderBreadcrumbs sx={{ flexGrow: 1, margin: '20px 20px' }} heading="Project Type" />
            <Button sx={{ mb :2 }}
              disabled={explodedPermissions?.some(permission => ['Manage Project Types'].includes(permission)) ? false : true}
              onClick={() => {setOpenModal(true)
              // navigate(PATH_DASHBOARD.community.addtype);
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
                Add Project Type
            </Button>
        </Stack>

        <Card>
          <CustomerOrderToolbar 
            filterName={filterName} 
            onFilterName={handleFilterByName} 
            item="project types"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={slicedData?.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {loading ? (
                    <Stack alignItems="center" justifyContent="center" style={{ height: '100%',width:'100%' }}>
                    <CircularProgress
                    color="primary"
                    variant="indeterminate"
                    />
                  </Stack>
                  ) : (
                    <>
                    {slicedData?.map((row) => {
                      let { id,name,status, authorized_by, timestamp } = row;
                      let statusText;
                      (status === 1) ? statusText = "Active" : statusText = "Inactive";
                      let addedon = moment.unix(timestamp).format('DD-MM-YYYY');
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell padding="checkbox">
                          </TableCell>
                          <TableCell align="center">{name}</TableCell>
                          <TableCell align="center">{authorized_by}</TableCell>
                          <TableCell align="center">{addedon}</TableCell>
                          <TableCell align="center">
                            <Label
                              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                              color={(status === 1 && 'info') || 'error'}
                            >
                              {sentenceCase(statusText)}
                            </Label>
                          </TableCell>

                          <TableCell align="center">
                            {/* <ProductsMenu onDelete={() => handleDeleteUser(id)} userName={name} id={id} status={status}/> */}
                            <UserMoreMenu
                                onDelete={()=>{if(explodedPermissions?.some(permission => ['Manage Project Types'].includes(permission))){
                                  setOpenDeletePop(true);setDeletingId(id);setDeletingName(name);
                                }else{
                                  enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                }}}
                                onEdit={()=>{if(explodedPermissions?.some(permission => ['Manage Project Types'].includes(permission))){
                                  setOpenModal(true); setEditTypeId(id)
                                }else{
                                  enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                }}}
                                onStatusChange={()=>{if(explodedPermissions?.some(permission => ['Manage Project Types'].includes(permission))){
                                  handleMenuClick(id,status)
                                }else{
                                  enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                }}}
                                status={status}
                                oppName={name}
                                oppId={id}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                    </>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} msg="Project type"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Confirmation for deleting community */}
      <ConfirmationPopup
        open={openDeletePop}
        handleClose={()=>{setOpenDeletePop(false)}}
        message={`You want to delete ${deletingName} ! `}
        handleClick={()=>{handleDeleteClick(deletingId, 2)}}
      />

    <Modal
      modalTitle={editTypeId ? 'Edit Project Type' : 'Add Project Type'}
      open={openModal}
      handleClose={() => {setOpenModal(false); setEditTypeId()}}
    >
        <AddCommunityType setOpenModal={()=>setOpenModal(!openModal)} editTypeId={editTypeId} setEditTypeId={()=>setEditTypeId()}/>
    </Modal>
    </Page>
  );
}
