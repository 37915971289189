import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, List, ListItem, Collapse, ListItemText, ListItemIcon, ListSubheader } from '@material-ui/core';
import { PATH_DESKTOP } from 'src/routes/paths';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import useWorkSpaceOption from 'src/hooks/useWorkspaceOptions';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary
  })
);

const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(2.5),
  // color: theme.palette.text.secondary,  temporary change
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    // backgroundColor: isActiveSub ? theme.palette.primary.main : color || theme.palette.success.main,   temporary change
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active, explodedPermissions, userType,isCompact }) {
  const theme = useTheme();
  const userData = JSON.parse(localStorage.getItem('user'))
  const Title = userData.job_title
  const { workSpaceTitle } = useWorkSpaceOption()
  let JOB_TITLE= Title ? Title :'Jobs'
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children,color } = item;
  const [open, setOpen] = useState(isCompact && isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleListItemClick = (path,event) => {
    if (path.endsWith('/incoming-window')) {
      window.open(PATH_DESKTOP.root, '_blank','width=1000,height=1000');
      event.preventDefault();
    }
  };

  const checkChildPermissions = (permissionChecks, items) => {
    return permissionChecks.map(({ permissions, title }) => {
      const hasAnyPermission = permissions.some((perm) =>
        explodedPermissions.includes(perm)
      );

      if (hasAnyPermission) {
        const filteredItems = items.filter((item) => title === item.title)
        if (filteredItems.length > 0) {
          return filteredItems.map((item) => {
            const { title, path } = item;
            const isActiveSub = active(path);
            return (
              <ListItemStyle
                key={title}
                component={RouterLink}
                onClick={(e)=>handleListItemClick(path,e)}
                to={path}
                sx={{
                  ...(isActiveSub && activeSubStyle)
                }}
              >
                <ListItemIconStyle>
                  <Box
                    component="span"
                    sx={{                     
                      width: 4,
                      height: 4,
                      display: 'flex',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'text.disabled',
                      transition: (theme) => theme.transitions.create('transform'),
                      ...(isActiveSub && {
                        transform: 'scale(2)',
                        bgcolor: 'primary.main'
                      })
                    }}
                  />
                </ListItemIconStyle>
                {/* <ListItemText disableTypography primary={title} /> */}
                {!isCompact && <ListItemText disableTypography primary={title} />}
              </ListItemStyle>
            )
          })
        };
      }
      return null;
    });
  };
  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot ? activeRootStyle : color==='success' ? {bgcolor:theme.palette.success.main} : {})  //temporary
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
         {!isCompact && <ListItemText disableTypography primary={title} />}
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {userType !== 3 && (
              checkChildPermissions([
                {
                  permissions: ['View Contacts','Add Contact', 'Activate/Deactivate Contact', 'Delete Contact', 'Edit Contact', 'View Contact'],
                  title: 'Contacts',
                },
                {
                  permissions: ['View All Leads','Add Lead', 'Activate/Deactivate Lead', 'Delete Lead', 'View Lead', 'Edit Lead','View Lead Statuses','Add Lead Status','Edit Lead Status','Activate/Deactivate Lead Status','Delete Lead Status'],
                  title: 'Leads',
                },
                {
                  permissions: ['View Opportunities','Add Opportunity', 'Activate/Deactivate Opportunity', 'Delete Opportunity', 'View Opportunity', 'Edit Opportunity'],
                  title: 'Opportunities',
                },
                {
                  permissions: ['View Onboarding Workflows','Create Onboarding Workflows','Edit Onboarding Workflows', 'Delete Onboarding workflows','Activate/Deactivate Onboarding Workflows'],
                  title: 'Workflow',
                },
                {
                  permissions: ['View Onboarding Workflows','Create Onboarding Workflows','Edit Onboarding Workflows', 'Delete Onboarding workflows','Activate/Deactivate Onboarding Workflows'],
                  title: 'Production Workflow',
                },
                {
                  permissions: ['View Custom Field Template','Create Custom Field Template', 'Activate/Deactivate Custom Field Template','Delete Custom Field Template'],
                  title: 'Custom Field Template',
                },
                {
                  permissions: ['View Questionnaire Template','Create Questionnaire Template','Activate/Deactivate Questionnaire Template','Delete Questionnaire Template'],
                  title: 'Questionnaire Templates',
                },
                {
                  permissions: ['View Sign Agreements','Create Sign Agreements', 'Edit Sign Agreements','Activate/Deactivate Sign Agreements','Delete Sign Agreements'],
                  title: 'Sign Agreements',
                },
                {
                  permissions: ['View Departments','Add Department','Activate/Deactivate Department','Delete Department','View Department','Edit Department'],
                  title: 'Departments',
                },
                {
                  permissions: ['View team members','Inviting team members','Add team members','Edit team members','Delete team members'],
                  title: 'Team Members',
                },
                {
                  permissions: ['View All User Roles','Add User Roles','Edit User Roles','Activate/Deactivate User Roles','Delete User Roles'],
                  title: 'User Roles',
                },
                {
                  permissions: ['Add New Installation','View All Installations'],
                  title: 'Installation',
                },
                {
                  permissions: ['View Installation Templates','Add Installation Templates','Delete Installation Templates'],
                  title: 'Installation Template',
                },             
                {
                  permissions: ['Time Tracking'],
                  title: 'Time Tracking',
                },
                {
                  permissions: ['Add Staff Payout','Edit Staff Payout','View All Staff Payout','Payout based on Time-Tracking'],
                  title: 'Staff Payout',
                },
                {
                  permissions: ['View All Staff Schedules','Add Staff Schedule'],
                  title: 'Staff Scheduler',
                },
                {
                  permissions: ['Add Email', 'Deactivate Email', 'Delete Email'],
                  title: 'Emails',
                },
                {
                  permissions: ['Add Calendar', 'Deactivate Calendar', 'Delete Calendar'],
                  title: 'Calendar',
                },
                {
                  permissions: ['Add Meeting', 'Deactivate Meeting', 'Delete Meeting',],
                  title: 'Meetings',
                },
                {
                  permissions: ['Call Logs'],
                  title: 'Calls',
                },
                {
                  permissions: ['SMS Logs'],
                  title: 'SMS',
                },
                {
                  permissions: ['View Ticket','Add Ticket', 'Delete Ticket', 'Edit Ticket'],
                  title: 'Tickets',
                },
                {
                  permissions: ['View Campaigns','Add Campaign', 'Activate/Deactivate Campaign', 'Delete Campaign', 'View Campaign', 'Edit Campaign','View Custom Contact Form','Add Custom Contact Form','Delete Custom Contact Form','View Customization form','View/Export Enquiries'],
                  title: 'Campaigns',
                },
                {
                  permissions: ['View Jobs','Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs','Manage Job Cost'],
                  title: `All ${workSpaceTitle}`,
                },
                {
                  permissions: ['View Job Types','Add Job Types','Edit Job Types','Activate/Deactivate Job Types','Delete Job Types'],
                  title: `${workSpaceTitle} Type`,
                },
                {
                  permissions: ['View Job Categories','Add Job Categories','Edit Job Categories','Activate/Deactivate Job Categories','Delete Job Categories'],
                  title: `${workSpaceTitle} Category`,
                },
                {
                  permissions: ['Calls'],
                  title: 'incoming window',
                },
                {
                  permissions: ['Call Analytics'],
                  title: 'analytics',
                },
                {
                  permissions: ['Manage Call flow','View team members','Inviting team members','Add team members','Edit team members', 'Delete team members','View Departments', 'Add Department', 'Activate/Deactivate Department', 'Delete Department', 'Edit Department'],
                  title: 'account setup',
                },
                {
                  permissions: ['Call Logs'],
                  title: 'call logs',
                },
                {
                  permissions: ['SMS Logs'],
                  title: 'sms logs',
                },
                {
                  permissions: ['Access Call Recordings'],
                  title: 'recordings',
                },
                // {
                //   permissions: ['Monitor Call'],
                //   title: 'monitor calls',
                // },
                // {
                //   permissions: ['Inviting clients', 'Assigning relationship managers'],
                //   title: 'all clients',
                // },
                {
                  permissions: ['View Catalog','View Catalogs','Add Catalog','Edit Catalog','Activate/Deactivate Catalog','Delete Catalog' ],
                  title: 'Catalog',
                },
                {
                  permissions: ['View all services','Add services','Edit services','Activate/Deactivate Services','Delete Services' ],
                  title: 'Services',
                },
                {
                  permissions: ['View Product Formulas','Add Product Formula','Edit Product Formula','Delete Product Formula'],
                  title: 'Product Formula',
                },
                {
                  permissions: ['View Materials','View Material Label','Add Material Label','Edit Material Label','Activate/Deactivate Material Label','Delete Material Label','View Finished Product','Add Finished Product'],

                  title: 'Material Inventory',
                },
                {
                  permissions: ['Inventory Audit','Edit Inventory','View Inventory Audit Log'],
                  title: 'Inventory Audit',
                },
                {
                  permissions: ['View Categories','Add category','Edit category','Activate/Deactivate category','Delete category'],
                  title: 'Category',
                },
                {
                  permissions: ['View Sub Categories','Add Sub category','Edit Sub category','Activate/Deactivate Sub category','Delete Sub category'],
                  title: 'Sub Category',
                },
                {
                  permissions: ['Transportation'],
                  title: 'Transportation',
                },
                {
                  permissions: ['Manage Routes'],
                  title: 'Routes',
                },
                {
                  permissions: ['Route Optimization'],
                  title: 'Route Optimaization',
                },
                {
                  permissions: ['Manage Drivers'],
                  title: 'Drivers',
                },
                {
                  permissions: ['Manage Vehicles'],
                  title: 'Vehicles',
                },
                {
                  permissions: ['Track drivers'],
                  title: 'Track Drivers',
                },
                {
                  permissions: ['View Vendors','Add Vendors','Edit Vendors','Delete Vendors'],
                  title: 'All Vendors',
                },
                {
                  permissions: ['View Tax category'],
                  title: 'Tax categories',
                },
                {
                  permissions: ['View All Orders','Create Order','Edit Order','Cancel Order','View Order','View Invoice','Make payment','Receive Order'],
                  title: 'Receive Inventory Orders',
                },
              ], children)
            )}

            {userType == 1 && (
              checkChildPermissions([
                {
                  permissions: ['Call Settings'],
                  title: 'call settings',
                },
              ], children))}

            {/* {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })} */}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot ? activeRootStyle : color==='success' ? {bgcolor:theme.palette.success.main} : {}) //temporary
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
    { !isCompact &&<ListItemText disableTypography primary={title} /> }
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig,isCompact, ...other }) {

  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  // ----------------------------------------------------------------------
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user);
  const userType = userData.user_type;
  const explodedPermissions = userData.exploded_permissions;
  const Title = userData.job_title
  const { workSpaceTitle } = useWorkSpaceOption()
  let JOB_TITLE= Title ? Title :'Jobs'


  const checkPermissions = (permissionChecks, items) => {
    return permissionChecks.map(({ permissions, title,name }) => {
      const hasAnyPermission = permissions.some((perm) =>
        explodedPermissions.includes(perm)
      );

      if (hasAnyPermission) {
        return items
          .filter((item) => name.includes(item.name))
          .map((item) => (
            <NavItem key={item.title}  isCompact={isCompact} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
          ));
      }
      return null;
    });
  };


  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {/* <ListSubheaderStyle>{subheader}</ListSubheaderStyle> */}
            {/* --------------------------------------------------------------------------------------------------------- */}
            {/* {userType == 1 && items.map((item) => (
              <NavItem key={item.title} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType}/>
            ))
            } */}
            {/* {userType == 3 &&
              items
                .filter(item => item.type == userType)
                .map(item => (
                  <NavItem key={item.title} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType}/>
                ))} */}
            {/* --------------------------------------------------------------------------------------------------------- */}
            {
              items
                .filter(item => item.title == 'Production control board')
                .map(item => (
                  <NavItem isCompact={isCompact} key={item.title} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
                ))
            }
            
            {(userType == 3) &&
              items
                .filter(item => item.name == 'OnboardingClient')
                .map(item => (
                  <NavItem isCompact={isCompact} key={item.name} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
                ))
            }
            {(userType == 3) &&
              items
                .filter(item => item.title == 'official request')
                .map(item => {
                  let newItem = { ...item,title: userData.isVendor ? 'RFP' : 'official request'}
                  return <NavItem isCompact={isCompact} key={item.title} item={newItem} active={match} explodedPermissions={explodedPermissions} userType={userType} />
                })
            }
            {(userType == 3) &&
              items
                .filter(item => item.title == 'Projects')
                .map(item => (
                  <NavItem isCompact={isCompact} key={item.title} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
                ))
            }
            {(userType == 3) &&
              items
                .filter(item => item.name === "job_client")
                .map(item => (
                  <NavItem isCompact={isCompact} key={item.title} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
                ))
            }
            {/* --------------------------------------------------------------------------------------------------------- */}
            {userType !== 3 &&
              checkPermissions([
                {
                  permissions: ['Installation Calendar'],
                  name: 'Installation Calendar',
                },
                {
                  permissions: ['Calls','Monitor Call','Access Call Recordings','Call Logs','Call Analytics','Manage Call flow','Call Settings','SMS Logs','View team members','Inviting team members','Add team members','Edit team members', 'Delete team members', 'View Departments','Add Department', 'Activate/Deactivate Department', 'Delete Department', 'Edit Department'],
                  name: 'call center',
                },
                {
                  permissions: ['View request','Create Request', 'Edit Request', 'Delete request','Add vendor to request','Add client to request','View Markup'],
                  name: 'official request',
                },
                {
                  permissions: ['View Projects','Add Projects','Edit Projects','Activate/Deactivate Projects','Delete Projects','Manage Project Types'],
                  name: 'Projects',
                },
                {
                  permissions: ['View Jobs','Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs','View Job Types','Add Job Types','Edit Job Types','Activate/Deactivate Job Types','Delete Job Types','View Job Categories','Add Job Categories','Edit Job Categories','Activate/Deactivate Job Categories','Delete Job Categories','Manage Job Cost'],
                  name: workSpaceTitle,
                },
                {
                  permissions: ['Add New Installation','View All Installations','View Installation Templates','Add Installation Templates','Delete Installation Templates'],
                  name: 'installation',
                },
                {
                  permissions: ['View all Vendors','View Vendors','Add Vendors','Edit Vendors','Delete Vendors','View Tax category','View All Orders','Create Order','Edit Order','Cancel Order','View Order','View Invoice','Make payment','Receive Order'],
                  name: 'purchase',
                },
                {
                  permissions: ['View Onboarding Workflows','Create Onboarding Workflows', 'Edit Onboarding Workflows','Activate/Deactivate Onboarding Workflows', 'Delete Onboarding workflows','View Custom Field Template','Create Custom Field Template','Activate/Deactivate Custom Field Template','Delete Custom Field Template','View Questionnaire Template','Create Questionnaire Template','Activate/Deactivate Questionnaire Template','Delete Questionnaire Template','View Sign Agreements','Create Sign Agreements','Edit Sign Agreements','Activate/Deactivate Sign Agreements','Delete Sign Agreements'],
                  // title: 'Onboarding',
                  name: 'Onboarding',
                },
                {
                  permissions: ['View team members','Inviting team members','Add team members','Edit team members','Delete team members','View Departments','Add Department','Activate/Deactivate Department','Delete Department','Edit Department','Add User Roles','Edit User Roles','Activate/Deactivate User Roles','Delete User Roles','Time Tracking','Add Staff Payout','Edit Staff Payout','View All Staff Payout','View All Staff Schedules','Add Staff Schedule','Payout based on Time-Tracking','View All User Roles'],
                  // title: 'Staff Management',
                  name: 'Staff Management',
                },
                {
                  permissions: ['View Contacts','Add Contact', 'Edit Contact', 'Delete Contact','View Contact','Activate/Deactivate Contact','View All Leads','Add Lead','Edit Lead','View Lead','Activate/Deactivate Lead','Delete Lead','Convert Lead','View Lead Statuses','Add Lead Status','Edit Lead Status','Activate/Deactivate Lead Status','Delete Lead Status','View Opportunities','Add Opportunity','Edit Opportunity','View Opportunity','Activate/Deactivate Opportunity','Delete Opportunity','View Ticket','Add Ticket','Edit Ticket','Delete Ticket','View Campaigns','Add Campaign','Activate/Deactivate Campaign','Delete Campaign','View Campaign','Edit Campaign','View Custom Contact Form','Add Custom Contact Form','Delete Custom Contact Form','View Customization form','View/Export Enquiries','Call Logs','SMS Logs',],
                  name: 'CRM',
                },
                {
                  permissions: ['Inviting clients', 'Edit clients','Delete clients','View Clients'],
                  name: 'clients',
                },
                {
                  permissions: ['Insights'],
                  name: 'Insights',
                },
                {
                  permissions: ['Inviting clients', 'Assigning relationship managers'],
                  name: 'Groups',
                },
                {
                  permissions: ['Access all payments', 'Mark as paid', 'Add New Payment'],
                  name: 'Payments',
                },
                // {
                //   permissions: ['Whatsapp'],
                //   title: 'Whatsapp',
                // },
                {
                  permissions: ['View Catalog','View Catalogs','Add Catalog','Edit Catalog','Activate/Deactivate Catalog','Delete Catalog','View Material Label','View Materials','Add Material Label','Edit Material Label','Activate/Deactivate Material Label','Delete Material Label','View Finished Product','Add Finished Product','Inventory Audit','Edit Inventory','View Inventory Audit Log','View Categories','Add category','Edit category','Activate/Deactivate category','Delete category','View Sub Categories','Add Sub category','Edit Sub category','Activate/Deactivate Sub category','Delete Sub category','View Product Formulas','Add Product Formula','Edit Product Formula','Delete Product Formula','View all services','Add services','Edit services','Activate/Deactivate Services','Delete Services'],
                  name: 'Inventory',
                },
                {
                  permissions: ['Transportation','Manage Routes','Route Optimization','Manage Drivers','Manage Vehicles','Track drivers',],
                  name: 'Trafficwise',
                },
                {
                  permissions: ['View Customer Orders'],
                  name: 'Customer Orders',
                },
              ], items)
            }

          </List>
        );
      })}
    </Box>
  );
}
