// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const userData = JSON.parse(localStorage.getItem('user'))

  return (
    <MAvatar
      src={userData.profile_pic}
      alt={userData.first_name}
      color={userData.profile_pic ? 'default' : (createAvatar(userData.first_name).color)}
      {...other}
    >
      {createAvatar(userData.first_name).name}
    </MAvatar>
  );
}
